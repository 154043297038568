import {Hex} from '../../utils/Hex';

export enum TileType {
    grass = 'Grass tile',
    hill = 'Mountain tile',
    rock = 'Path tile',
    water = 'Water tile',
    steel = 'Steel tile'
}

export type TileAnimation = {
    steps: number;
    duration: number;
};

export abstract class BaseTile {
    abstract type: TileType;
    abstract sprite: string;
    hex: Hex;

    elevation: number = 0;
    selectable: boolean = false;
    animation?: TileAnimation;

    module?: any;
    effect?: any;

    constructor(hex_: Hex) {
        this.hex = hex_;
    }

    printKey: () => string = () => `(${this.hex.q}, ${this.hex.r})`
}