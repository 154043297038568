import {MAP_IDENTIFIER} from 'von-game/maps/MapTypes';
import {CHARACTER_IDENTIFIER, SpriteDescriptor} from 'von-game/sprites/SpriteTypes';
import {Coordinates} from 'von-game/utils/GameTypes';
import {getCurrentHitbox, getZIndex} from 'von-game/utils/GameUtils';

import {getCharacterPlane, getCharacterZIndex, redefinePosition, setMap} from './0_StateContainer';
import {processSpriteEvent} from './9_EventContainer';


// Selectors, used to update the game elements without re-rendering the components
let mapFrameElement = document.querySelector<HTMLDivElement>('.map-frame');
let mapElement = document.querySelector<HTMLDivElement>(`.${MAP_IDENTIFIER}`);
let characterElement = document.querySelector<HTMLDivElement>(`.${CHARACTER_IDENTIFIER}`);

// Observer used to check if the selectors identify valid elements
// When one of the elements is changed (new map or new character), the observer will automatically
// reconnect the new elements
const observer = new MutationObserver(((mutations, me) => {
    mapFrameElement = document.querySelector<HTMLDivElement>('.map-frame');
    mapElement = document.querySelector<HTMLDivElement>(`.${MAP_IDENTIFIER}`);
    characterElement = document.querySelector<HTMLDivElement>(`.${CHARACTER_IDENTIFIER}`);
}));

observer.observe(document, {
    childList: true,
    subtree: true
});

// Map variables
let isChangingMap = false;
export const getChangingMap = () => isChangingMap;

export const isElementUndefined = () => !mapFrameElement || !mapElement || !characterElement;

export const startMapChange = (newMap: string,
    positionDefinition: (oldPosition: Coordinates) => Coordinates) => {
    // Hide the current map then change the map
    if (mapFrameElement) {
        mapFrameElement.style.opacity = '0';
    }
    setTimeout(() => {
        isChangingMap = true;
        setMap(newMap);
        redefinePosition(positionDefinition);
    }, 300);
};

export const zoom = (zoomValue: number) => {
    if (mapFrameElement) {
        mapFrameElement.style.transform = `scale(${zoomValue})`;
    }
};

export const resetZoom = () => {
    if (mapFrameElement) {
        mapFrameElement.style.transform = 'scale(1)';
    }
};

export const endMapChange = () => {
    // If the map isn't changing, ignore the action
    if (!isChangingMap) {
        return;
    }
    // End the map change
    isChangingMap = false;
    // Wait for the observer to reconnect the map before displaying it
    setTimeout(() => {
        if (mapFrameElement) {
            mapFrameElement.style.opacity = '1';
        }
    }, 100);
};

export const setCharacterPosition = (position: Coordinates) => {
    if (characterElement) {
        characterElement.style.transform = `translate3d(
            ${position.x}px,
            ${position.y}px,
            0
        )`;
        characterElement.style.zIndex = `${getCharacterZIndex()}`;
    }
};

export const setCharacterAttribute = (attribute: string, value: string) => {
    if (characterElement) {
        characterElement.setAttribute(attribute, value);
    }
};

export const setMapPosition = (position: Coordinates) => {
    if (mapElement) {
        mapElement.style.transform = `translate3d(
            ${position.x}px,
            ${position.y}px,
            0
        )`;
    }
};

const isEntityOnPlane = (entityPlane?: number) => {
    // If the plane is not defined, the entity is universal
    if (!entityPlane) {
        return true;
    }
    const characterPlane = getCharacterPlane();
    // Should never happen
    if (!characterPlane) {
        return false;
    }
    return entityPlane === characterPlane;
};

export const setSpritePosition = (sprite: SpriteDescriptor) => {
    const spriteElement: any = document.querySelector(`.${sprite.identifier}`);
    // If the element exists
    if (spriteElement) {
        processSpriteEvent(sprite, spriteElement);
        const {position, hitbox} = sprite;

        // Reposition the sprite
        spriteElement.style.transform = `translate3d(
            ${position.x}px,
            ${position.y}px,
            0
        )`;
        // Set the correct z-index
        const currentHitbox = getCurrentHitbox(hitbox, position);
        spriteElement.style.zIndex = `${getZIndex(currentHitbox)}`;
        // Change display depending on the plane
        spriteElement.style.opacity = `${isEntityOnPlane(position.plane) ? 1 : 0}`;
    }
};