import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {DraggableMap, Menu} from './components';
import {Tile} from './components/Tile';
import {TestLevel} from './levels/TestLevel';
import {GAME_WINDOW_HEIGHT, GAME_WINDOW_WIDTH, MAP_FRAME_HEIGHT, MAP_FRAME_WIDTH, MENU_FRAME_HEIGHT, MENU_FRAME_WIDTH, X_DRAG_LIMIT, Y_DRAG_LIMIT} from './utils/GameConstants';
import {Coordinates} from './utils/GameTypes';
import {getHexFromPos, getTile} from './utils/GameUtils';
import {Hex} from './utils/Hex';

const GameContainer = styled.div`
    position: absolute;
    width: ${GAME_WINDOW_WIDTH}px;
    height: ${GAME_WINDOW_HEIGHT}px;
    background-color: black;
    border: 2px solid white;
    overflow: hidden;
`;

const MapContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${MAP_FRAME_WIDTH}px;
    height: ${MAP_FRAME_HEIGHT}px;
    background-color: black;
    border: 2px solid white;
    overflow: hidden;
`;

const MenuContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: ${MENU_FRAME_WIDTH}px;
    height: ${MENU_FRAME_HEIGHT}px;
    background-color: black;
    border: 2px solid white;
    overflow: hidden;
`;

// Game loop variables
const fps = 60;
const interval = 1000 / fps;
let lastTime = window.performance.now();
let currentTime = 0;
let delta = 0;

const levels = [TestLevel];

export const SiccinctContainer: React.FC = () => {
    const [selectedTile, setSelectedTile] = useState<Hex>();
    const [position, setPosition] = useState<Coordinates>({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [debug, setDebug] = useState(false);

    const tiles = levels[0];
    const requestRef: any = useRef();

    const updatePos = useCallback((movement: Coordinates) => {
        const newX = position.x + movement.x / zoom;
        const newY = position.y + movement.y / zoom;
        const newPos = {
            x: Math.abs(newX) > X_DRAG_LIMIT ? Math.sign(newX) * X_DRAG_LIMIT : newX,
            y: Math.abs(newY) > Y_DRAG_LIMIT ? Math.sign(newY) * Y_DRAG_LIMIT : newY
        };
        setPosition(newPos);
    }, [position, zoom]);

    const update = useCallback(() => {
        // Update all factory modules
        // Update all turrets
        // Update all enemies
    }, []);

    // Render
    const render = () => {
    };

    // Game loop
    useEffect(() => {
        const step = (newtime) => {
            requestRef.current = window.requestAnimationFrame(step);
            // Limit the processing speed to avoid inconsistencies between devices
            currentTime = newtime;
            delta = (currentTime - lastTime);
            if (delta > interval) {
                lastTime = currentTime - (delta % interval);
                update();
                render();
            }
        };
        lastTime = window.performance.now();
        requestRef.current = window.requestAnimationFrame(step);
        return () => window.cancelAnimationFrame(requestRef.current);
    }, [update]);

    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.code === 'KeyV') {
            setDebug(true);
        }
    }, []);

    const onKeyUp = useCallback((e: KeyboardEvent) => {
        if (e.code === 'KeyV') {
            setDebug(false);
        }
    }, []);

    const onWheel = useCallback((e: WheelEvent) => {
        if (e.deltaY > 0 && zoom > 0.7) {
            setZoom(zoom - 0.2);
        } else if (e.deltaY < 0 && zoom < 1.9) {
            setZoom(zoom + 0.2);
        }
    }, [zoom]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    useEffect(() => {
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [onKeyUp]);

    useEffect(() => {
        window.addEventListener('wheel', onWheel);
        return () => {
            window.removeEventListener('wheel', onWheel);
        };
    }, [onWheel]);

    const selectTile = useCallback((hex: Hex) => {
        if (hex.equals(selectedTile)) {
            setSelectedTile(undefined);
        } else {
            const tile = getTile(tiles, hex);
            if (tile && tile.selectable) {
                setSelectedTile(hex);
            } else {
                setSelectedTile(undefined);
            }
        }
    }, [selectedTile, tiles]);

    const renderTiles = useCallback(() => tiles.map(
        (tile, index) => {
            if (tile.hex.distance(getHexFromPos(position)) < 12) {
                return (
                    <Tile
                        debug={debug}
                        tile={tile}
                        selected={tile.hex.equals(selectedTile)}
                        select={selectTile}
                        key={index}
                    />
                );
            }
            return null;
        }
    ), [tiles, selectTile, selectedTile, position, debug]);

    return (
        <GameContainer className='siccinct-container'>
            <MapContainer className='map-container'>
                <DraggableMap position={position} zoom={zoom} updatePos={updatePos}>
                    {renderTiles()}
                </DraggableMap>
            </MapContainer>
            <MenuContainer className='menu-container'>
                <Menu
                    selectedTile={getTile(tiles, selectedTile)}
                    build={() => {}}
                    recycle={() => {}}
                />
            </MenuContainer>
        </GameContainer>
    );
};