import {GrassTile, HillTile, RockTile, SteelTile, WaterTile} from '../sprites';
import {Hex} from '../utils/Hex';

export const TestLevel = [
    new HillTile(new Hex(-20, 0)),
    new HillTile(new Hex(-20, 1)),
    new HillTile(new Hex(-20, 2)),
    new HillTile(new Hex(-20, 3)),
    new HillTile(new Hex(-20, 4)),
    new HillTile(new Hex(-20, 5)),
    new HillTile(new Hex(-20, 6)),
    new HillTile(new Hex(-20, 7)),
    new HillTile(new Hex(-20, 8)),
    new HillTile(new Hex(-20, 9)),
    new HillTile(new Hex(-20, 10)),
    new HillTile(new Hex(-20, 11)),
    new HillTile(new Hex(-20, 12)),
    new HillTile(new Hex(-20, 13)),
    new HillTile(new Hex(-20, 14)),
    new HillTile(new Hex(-20, 15)),
    new HillTile(new Hex(-20, 16)),
    new RockTile(new Hex(-20, 17)),
    new RockTile(new Hex(-20, 18)),
    new RockTile(new Hex(-20, 19)),
    new HillTile(new Hex(-20, 20)),
    new HillTile(new Hex(-19, -1)),
    new GrassTile(new Hex(-19, 0)),
    new GrassTile(new Hex(-19, 1)),
    new GrassTile(new Hex(-19, 2)),
    new GrassTile(new Hex(-19, 3)),
    new GrassTile(new Hex(-19, 4)),
    new GrassTile(new Hex(-19, 5)),
    new GrassTile(new Hex(-19, 6)),
    new GrassTile(new Hex(-19, 7)),
    new GrassTile(new Hex(-19, 8)),
    new GrassTile(new Hex(-19, 9)),
    new GrassTile(new Hex(-19, 10)),
    new GrassTile(new Hex(-19, 11)),
    new GrassTile(new Hex(-19, 12)),
    new GrassTile(new Hex(-19, 13)),
    new GrassTile(new Hex(-19, 14)),
    new GrassTile(new Hex(-19, 15)),
    new GrassTile(new Hex(-19, 16)),
    new RockTile(new Hex(-19, 17)),
    new RockTile(new Hex(-19, 18)),
    new RockTile(new Hex(-19, 19)),
    new HillTile(new Hex(-19, 20)),
    new HillTile(new Hex(-18, -2)),
    new GrassTile(new Hex(-18, -1)),
    new GrassTile(new Hex(-18, 0)),
    new GrassTile(new Hex(-18, 1)),
    new GrassTile(new Hex(-18, 2)),
    new GrassTile(new Hex(-18, 3)),
    new GrassTile(new Hex(-18, 4)),
    new GrassTile(new Hex(-18, 5)),
    new GrassTile(new Hex(-18, 6)),
    new GrassTile(new Hex(-18, 7)),
    new GrassTile(new Hex(-18, 8)),
    new GrassTile(new Hex(-18, 9)),
    new GrassTile(new Hex(-18, 10)),
    new GrassTile(new Hex(-18, 11)),
    new GrassTile(new Hex(-18, 12)),
    new GrassTile(new Hex(-18, 13)),
    new GrassTile(new Hex(-18, 14)),
    new GrassTile(new Hex(-18, 15)),
    new GrassTile(new Hex(-18, 16)),
    new RockTile(new Hex(-18, 17)),
    new RockTile(new Hex(-18, 18)),
    new RockTile(new Hex(-18, 19)),
    new HillTile(new Hex(-18, 20)),
    new HillTile(new Hex(-17, -3)),
    new GrassTile(new Hex(-17, -2)),
    new GrassTile(new Hex(-17, -1)),
    new GrassTile(new Hex(-17, 0)),
    new GrassTile(new Hex(-17, 1)),
    new GrassTile(new Hex(-17, 2)),
    new GrassTile(new Hex(-17, 3)),
    new GrassTile(new Hex(-17, 4)),
    new GrassTile(new Hex(-17, 5)),
    new GrassTile(new Hex(-17, 6)),
    new GrassTile(new Hex(-17, 7)),
    new GrassTile(new Hex(-17, 8)),
    new GrassTile(new Hex(-17, 9)),
    new GrassTile(new Hex(-17, 10)),
    new GrassTile(new Hex(-17, 11)),
    new GrassTile(new Hex(-17, 12)),
    new GrassTile(new Hex(-17, 13)),
    new GrassTile(new Hex(-17, 14)),
    new GrassTile(new Hex(-17, 15)),
    new GrassTile(new Hex(-17, 16)),
    new RockTile(new Hex(-17, 17)),
    new RockTile(new Hex(-17, 18)),
    new RockTile(new Hex(-17, 19)),
    new HillTile(new Hex(-17, 20)),
    new HillTile(new Hex(-16, -4)),
    new GrassTile(new Hex(-16, -3)),
    new GrassTile(new Hex(-16, -2)),
    new GrassTile(new Hex(-16, -1)),
    new GrassTile(new Hex(-16, 0)),
    new GrassTile(new Hex(-16, 1)),
    new GrassTile(new Hex(-16, 2)),
    new GrassTile(new Hex(-16, 3)),
    new GrassTile(new Hex(-16, 4)),
    new GrassTile(new Hex(-16, 5)),
    new GrassTile(new Hex(-16, 6)),
    new GrassTile(new Hex(-16, 7)),
    new GrassTile(new Hex(-16, 8)),
    new GrassTile(new Hex(-16, 9)),
    new GrassTile(new Hex(-16, 10)),
    new GrassTile(new Hex(-16, 11)),
    new GrassTile(new Hex(-16, 12)),
    new GrassTile(new Hex(-16, 13)),
    new GrassTile(new Hex(-16, 14)),
    new GrassTile(new Hex(-16, 15)),
    new GrassTile(new Hex(-16, 16)),
    new RockTile(new Hex(-16, 17)),
    new RockTile(new Hex(-16, 18)),
    new RockTile(new Hex(-16, 19)),
    new HillTile(new Hex(-16, 20)),
    new GrassTile(new Hex(-15, -4)),
    new GrassTile(new Hex(-15, -3)),
    new GrassTile(new Hex(-15, -2)),
    new GrassTile(new Hex(-15, -1)),
    new GrassTile(new Hex(-15, 0)),
    new GrassTile(new Hex(-15, 1)),
    new GrassTile(new Hex(-15, 2)),
    new GrassTile(new Hex(-15, 3)),
    new GrassTile(new Hex(-15, 4)),
    new GrassTile(new Hex(-15, 5)),
    new GrassTile(new Hex(-15, 6)),
    new GrassTile(new Hex(-15, 7)),
    new GrassTile(new Hex(-15, 8)),
    new GrassTile(new Hex(-15, 9)),
    new GrassTile(new Hex(-15, 10)),
    new GrassTile(new Hex(-15, 11)),
    new GrassTile(new Hex(-15, 12)),
    new GrassTile(new Hex(-15, 13)),
    new GrassTile(new Hex(-15, 14)),
    new GrassTile(new Hex(-15, 15)),
    new GrassTile(new Hex(-15, 16)),
    new RockTile(new Hex(-15, 17)),
    new RockTile(new Hex(-15, 18)),
    new RockTile(new Hex(-15, 19)),
    new HillTile(new Hex(-15, 20)),
    new GrassTile(new Hex(-14, -3)),
    new GrassTile(new Hex(-14, -2)),
    new GrassTile(new Hex(-14, -1)),
    new GrassTile(new Hex(-14, 0)),
    new GrassTile(new Hex(-14, 1)),
    new GrassTile(new Hex(-14, 2)),
    new GrassTile(new Hex(-14, 3)),
    new GrassTile(new Hex(-14, 4)),
    new GrassTile(new Hex(-14, 5)),
    new GrassTile(new Hex(-14, 6)),
    new GrassTile(new Hex(-14, 7)),
    new GrassTile(new Hex(-14, 8)),
    new GrassTile(new Hex(-14, 9)),
    new GrassTile(new Hex(-14, 10)),
    new GrassTile(new Hex(-14, 11)),
    new GrassTile(new Hex(-14, 12)),
    new GrassTile(new Hex(-14, 13)),
    new GrassTile(new Hex(-14, 14)),
    new GrassTile(new Hex(-14, 15)),
    new GrassTile(new Hex(-14, 16)),
    new RockTile(new Hex(-14, 17)),
    new RockTile(new Hex(-14, 18)),
    new RockTile(new Hex(-14, 19)),
    new HillTile(new Hex(-14, 20)),
    new HillTile(new Hex(-13, -7)),
    new GrassTile(new Hex(-13, -6)),
    new GrassTile(new Hex(-13, -3)),
    new GrassTile(new Hex(-13, -2)),
    new GrassTile(new Hex(-13, -1)),
    new GrassTile(new Hex(-13, 0)),
    new GrassTile(new Hex(-13, 1)),
    new GrassTile(new Hex(-13, 2)),
    new GrassTile(new Hex(-13, 3)),
    new GrassTile(new Hex(-13, 4)),
    new GrassTile(new Hex(-13, 5)),
    new GrassTile(new Hex(-13, 6)),
    new GrassTile(new Hex(-13, 7)),
    new GrassTile(new Hex(-13, 8)),
    new GrassTile(new Hex(-13, 9)),
    new GrassTile(new Hex(-13, 10)),
    new GrassTile(new Hex(-13, 11)),
    new GrassTile(new Hex(-13, 12)),
    new GrassTile(new Hex(-13, 13)),
    new GrassTile(new Hex(-13, 14)),
    new GrassTile(new Hex(-13, 15)),
    new GrassTile(new Hex(-13, 16)),
    new RockTile(new Hex(-13, 17)),
    new RockTile(new Hex(-13, 18)),
    new RockTile(new Hex(-13, 19)),
    new HillTile(new Hex(-13, 20)),
    new HillTile(new Hex(-12, -8)),
    new GrassTile(new Hex(-12, -7)),
    new GrassTile(new Hex(-12, -6)),
    new GrassTile(new Hex(-12, -5)),
    new GrassTile(new Hex(-12, -3)),
    new GrassTile(new Hex(-12, -2)),
    new GrassTile(new Hex(-12, -1)),
    new GrassTile(new Hex(-12, 0)),
    new GrassTile(new Hex(-12, 1)),
    new GrassTile(new Hex(-12, 2)),
    new GrassTile(new Hex(-12, 3)),
    new GrassTile(new Hex(-12, 4)),
    new GrassTile(new Hex(-12, 5)),
    new GrassTile(new Hex(-12, 6)),
    new GrassTile(new Hex(-12, 7)),
    new GrassTile(new Hex(-12, 8)),
    new GrassTile(new Hex(-12, 9)),
    new GrassTile(new Hex(-12, 10)),
    new GrassTile(new Hex(-12, 11)),
    new GrassTile(new Hex(-12, 12)),
    new GrassTile(new Hex(-12, 13)),
    new GrassTile(new Hex(-12, 14)),
    new GrassTile(new Hex(-12, 15)),
    new GrassTile(new Hex(-12, 16)),
    new RockTile(new Hex(-12, 17)),
    new RockTile(new Hex(-12, 18)),
    new RockTile(new Hex(-12, 19)),
    new HillTile(new Hex(-12, 20)),
    new HillTile(new Hex(-11, -9)),
    new GrassTile(new Hex(-11, -8)),
    new GrassTile(new Hex(-11, -7)),
    new GrassTile(new Hex(-11, -6)),
    new GrassTile(new Hex(-11, -5)),
    new GrassTile(new Hex(-11, -2)),
    new GrassTile(new Hex(-11, -1)),
    new GrassTile(new Hex(-11, 0)),
    new GrassTile(new Hex(-11, 1)),
    new GrassTile(new Hex(-11, 2)),
    new GrassTile(new Hex(-11, 3)),
    new GrassTile(new Hex(-11, 4)),
    new GrassTile(new Hex(-11, 5)),
    new GrassTile(new Hex(-11, 6)),
    new GrassTile(new Hex(-11, 7)),
    new GrassTile(new Hex(-11, 8)),
    new GrassTile(new Hex(-11, 9)),
    new GrassTile(new Hex(-11, 10)),
    new GrassTile(new Hex(-11, 11)),
    new GrassTile(new Hex(-11, 12)),
    new GrassTile(new Hex(-11, 13)),
    new GrassTile(new Hex(-11, 14)),
    new GrassTile(new Hex(-11, 15)),
    new GrassTile(new Hex(-11, 16)),
    new RockTile(new Hex(-11, 17)),
    new RockTile(new Hex(-11, 18)),
    new RockTile(new Hex(-11, 19)),
    new HillTile(new Hex(-11, 20)),
    new HillTile(new Hex(-10, -10)),
    new GrassTile(new Hex(-10, -9)),
    new GrassTile(new Hex(-10, -8)),
    new GrassTile(new Hex(-10, -7)),
    new GrassTile(new Hex(-10, -6)),
    new GrassTile(new Hex(-10, -5)),
    new GrassTile(new Hex(-10, -4)),
    new GrassTile(new Hex(-10, -2)),
    new GrassTile(new Hex(-10, -1)),
    new GrassTile(new Hex(-10, 0)),
    new GrassTile(new Hex(-10, 1)),
    new GrassTile(new Hex(-10, 2)),
    new GrassTile(new Hex(-10, 3)),
    new GrassTile(new Hex(-10, 4)),
    new GrassTile(new Hex(-10, 5)),
    new GrassTile(new Hex(-10, 6)),
    new GrassTile(new Hex(-10, 7)),
    new GrassTile(new Hex(-10, 8)),
    new GrassTile(new Hex(-10, 9)),
    new GrassTile(new Hex(-10, 10)),
    new GrassTile(new Hex(-10, 11)),
    new GrassTile(new Hex(-10, 12)),
    new GrassTile(new Hex(-10, 13)),
    new GrassTile(new Hex(-10, 14)),
    new GrassTile(new Hex(-10, 15)),
    new GrassTile(new Hex(-10, 16)),
    new RockTile(new Hex(-10, 17)),
    new RockTile(new Hex(-10, 18)),
    new RockTile(new Hex(-10, 19)),
    new HillTile(new Hex(-10, 20)),
    new HillTile(new Hex(-9, -11)),
    new GrassTile(new Hex(-9, -10)),
    new GrassTile(new Hex(-9, -9)),
    new GrassTile(new Hex(-9, -8)),
    new GrassTile(new Hex(-9, -7)),
    new GrassTile(new Hex(-9, -6)),
    new GrassTile(new Hex(-9, -5)),
    new GrassTile(new Hex(-9, -4)),
    new GrassTile(new Hex(-9, -3)),
    new GrassTile(new Hex(-9, -2)),
    new GrassTile(new Hex(-9, -1)),
    new GrassTile(new Hex(-9, 0)),
    new GrassTile(new Hex(-9, 1)),
    new GrassTile(new Hex(-9, 2)),
    new GrassTile(new Hex(-9, 3)),
    new GrassTile(new Hex(-9, 4)),
    new GrassTile(new Hex(-9, 5)),
    new GrassTile(new Hex(-9, 6)),
    new GrassTile(new Hex(-9, 7)),
    new GrassTile(new Hex(-9, 8)),
    new GrassTile(new Hex(-9, 9)),
    new GrassTile(new Hex(-9, 10)),
    new GrassTile(new Hex(-9, 11)),
    new GrassTile(new Hex(-9, 12)),
    new GrassTile(new Hex(-9, 13)),
    new GrassTile(new Hex(-9, 14)),
    new GrassTile(new Hex(-9, 15)),
    new GrassTile(new Hex(-9, 16)),
    new RockTile(new Hex(-9, 17)),
    new RockTile(new Hex(-9, 18)),
    new RockTile(new Hex(-9, 19)),
    new HillTile(new Hex(-9, 20)),
    new HillTile(new Hex(-8, -12)),
    new GrassTile(new Hex(-8, -11)),
    new GrassTile(new Hex(-8, -10)),
    new GrassTile(new Hex(-8, -9)),
    new GrassTile(new Hex(-8, -8)),
    new GrassTile(new Hex(-8, -7)),
    new GrassTile(new Hex(-8, -6)),
    new GrassTile(new Hex(-8, -5)),
    new GrassTile(new Hex(-8, -4)),
    new GrassTile(new Hex(-8, -3)),
    new GrassTile(new Hex(-8, -2)),
    new GrassTile(new Hex(-8, -1)),
    new GrassTile(new Hex(-8, 0)),
    new GrassTile(new Hex(-8, 1)),
    new GrassTile(new Hex(-8, 2)),
    new GrassTile(new Hex(-8, 3)),
    new GrassTile(new Hex(-8, 4)),
    new GrassTile(new Hex(-8, 5)),
    new GrassTile(new Hex(-8, 6)),
    new GrassTile(new Hex(-8, 7)),
    new GrassTile(new Hex(-8, 8)),
    new GrassTile(new Hex(-8, 9)),
    new GrassTile(new Hex(-8, 10)),
    new GrassTile(new Hex(-8, 11)),
    new GrassTile(new Hex(-8, 12)),
    new GrassTile(new Hex(-8, 13)),
    new GrassTile(new Hex(-8, 14)),
    new GrassTile(new Hex(-8, 15)),
    new GrassTile(new Hex(-8, 16)),
    new RockTile(new Hex(-8, 17)),
    new RockTile(new Hex(-8, 18)),
    new RockTile(new Hex(-8, 19)),
    new HillTile(new Hex(-8, 20)),
    new HillTile(new Hex(-7, -13)),
    new GrassTile(new Hex(-7, -12)),
    new GrassTile(new Hex(-7, -11)),
    new GrassTile(new Hex(-7, -10)),
    new GrassTile(new Hex(-7, -9)),
    new GrassTile(new Hex(-7, -8)),
    new GrassTile(new Hex(-7, -7)),
    new GrassTile(new Hex(-7, -6)),
    new GrassTile(new Hex(-7, -5)),
    new GrassTile(new Hex(-7, -4)),
    new GrassTile(new Hex(-7, -3)),
    new GrassTile(new Hex(-7, -2)),
    new GrassTile(new Hex(-7, -1)),
    new GrassTile(new Hex(-7, 0)),
    new GrassTile(new Hex(-7, 1)),
    new GrassTile(new Hex(-7, 2)),
    new GrassTile(new Hex(-7, 3)),
    new GrassTile(new Hex(-7, 4)),
    new GrassTile(new Hex(-7, 5)),
    new GrassTile(new Hex(-7, 6)),
    new GrassTile(new Hex(-7, 7)),
    new GrassTile(new Hex(-7, 8)),
    new GrassTile(new Hex(-7, 9)),
    new GrassTile(new Hex(-7, 10)),
    new GrassTile(new Hex(-7, 11)),
    new GrassTile(new Hex(-7, 12)),
    new GrassTile(new Hex(-7, 13)),
    new GrassTile(new Hex(-7, 14)),
    new GrassTile(new Hex(-7, 15)),
    new GrassTile(new Hex(-7, 16)),
    new RockTile(new Hex(-7, 17)),
    new RockTile(new Hex(-7, 18)),
    new RockTile(new Hex(-7, 19)),
    new HillTile(new Hex(-7, 20)),
    new HillTile(new Hex(-6, -14)),
    new GrassTile(new Hex(-6, -13)),
    new GrassTile(new Hex(-6, -12)),
    new GrassTile(new Hex(-6, -11)),
    new GrassTile(new Hex(-6, -10)),
    new GrassTile(new Hex(-6, -9)),
    new GrassTile(new Hex(-6, -8)),
    new GrassTile(new Hex(-6, -7)),
    new GrassTile(new Hex(-6, -6)),
    new GrassTile(new Hex(-6, -5)),
    new GrassTile(new Hex(-6, -4)),
    new GrassTile(new Hex(-6, -3)),
    new GrassTile(new Hex(-6, -2)),
    new GrassTile(new Hex(-6, -1)),
    new GrassTile(new Hex(-6, 0)),
    new GrassTile(new Hex(-6, 1)),
    new GrassTile(new Hex(-6, 2)),
    new GrassTile(new Hex(-6, 3)),
    new GrassTile(new Hex(-6, 4)),
    new GrassTile(new Hex(-6, 5)),
    new GrassTile(new Hex(-6, 6)),
    new GrassTile(new Hex(-6, 7)),
    new GrassTile(new Hex(-6, 8)),
    new GrassTile(new Hex(-6, 9)),
    new GrassTile(new Hex(-6, 10)),
    new GrassTile(new Hex(-6, 11)),
    new GrassTile(new Hex(-6, 12)),
    new GrassTile(new Hex(-6, 13)),
    new GrassTile(new Hex(-6, 14)),
    new GrassTile(new Hex(-6, 15)),
    new GrassTile(new Hex(-6, 16)),
    new RockTile(new Hex(-6, 17)),
    new RockTile(new Hex(-6, 18)),
    new RockTile(new Hex(-6, 19)),
    new HillTile(new Hex(-6, 20)),
    new HillTile(new Hex(-5, -15)),
    new GrassTile(new Hex(-5, -14)),
    new GrassTile(new Hex(-5, -13)),
    new GrassTile(new Hex(-5, -12)),
    new GrassTile(new Hex(-5, -11)),
    new GrassTile(new Hex(-5, -10)),
    new GrassTile(new Hex(-5, -9)),
    new GrassTile(new Hex(-5, -8)),
    new GrassTile(new Hex(-5, -7)),
    new GrassTile(new Hex(-5, -6)),
    new GrassTile(new Hex(-5, -5)),
    new GrassTile(new Hex(-5, -4)),
    new GrassTile(new Hex(-5, -3)),
    new GrassTile(new Hex(-5, -2)),
    new GrassTile(new Hex(-5, -1)),
    new GrassTile(new Hex(-5, 0)),
    new GrassTile(new Hex(-5, 1)),
    new GrassTile(new Hex(-5, 2)),
    new GrassTile(new Hex(-5, 3)),
    new GrassTile(new Hex(-5, 4)),
    new GrassTile(new Hex(-5, 5)),
    new GrassTile(new Hex(-5, 6)),
    new GrassTile(new Hex(-5, 7)),
    new GrassTile(new Hex(-5, 8)),
    new GrassTile(new Hex(-5, 9)),
    new GrassTile(new Hex(-5, 10)),
    new GrassTile(new Hex(-5, 11)),
    new GrassTile(new Hex(-5, 12)),
    new GrassTile(new Hex(-5, 13)),
    new GrassTile(new Hex(-5, 14)),
    new GrassTile(new Hex(-5, 15)),
    new GrassTile(new Hex(-5, 16)),
    new RockTile(new Hex(-5, 17)),
    new RockTile(new Hex(-5, 18)),
    new RockTile(new Hex(-5, 19)),
    new HillTile(new Hex(-5, 20)),
    new HillTile(new Hex(-4, -16)),
    new GrassTile(new Hex(-4, -15)),
    new GrassTile(new Hex(-4, -14)),
    new GrassTile(new Hex(-4, -13)),
    new GrassTile(new Hex(-4, -12)),
    new GrassTile(new Hex(-4, -11)),
    new GrassTile(new Hex(-4, -10)),
    new GrassTile(new Hex(-4, -9)),
    new GrassTile(new Hex(-4, -8)),
    new GrassTile(new Hex(-4, -7)),
    new GrassTile(new Hex(-4, -6)),
    new GrassTile(new Hex(-4, -5)),
    new GrassTile(new Hex(-4, -4)),
    new GrassTile(new Hex(-4, -3)),
    new GrassTile(new Hex(-4, -2)),
    new GrassTile(new Hex(-4, -1)),
    new GrassTile(new Hex(-4, 0)),
    new GrassTile(new Hex(-4, 1)),
    new GrassTile(new Hex(-4, 2)),
    new GrassTile(new Hex(-4, 3)),
    new GrassTile(new Hex(-4, 4)),
    new GrassTile(new Hex(-4, 5)),
    new GrassTile(new Hex(-4, 6)),
    new GrassTile(new Hex(-4, 7)),
    new GrassTile(new Hex(-4, 8)),
    new GrassTile(new Hex(-4, 9)),
    new GrassTile(new Hex(-4, 10)),
    new GrassTile(new Hex(-4, 11)),
    new GrassTile(new Hex(-4, 12)),
    new GrassTile(new Hex(-4, 13)),
    new GrassTile(new Hex(-4, 14)),
    new GrassTile(new Hex(-4, 15)),
    new GrassTile(new Hex(-4, 16)),
    new RockTile(new Hex(-4, 17)),
    new RockTile(new Hex(-4, 18)),
    new RockTile(new Hex(-4, 19)),
    new HillTile(new Hex(-4, 20)),
    new HillTile(new Hex(-3, -17)),
    new GrassTile(new Hex(-3, -16)),
    new GrassTile(new Hex(-3, -15)),
    new GrassTile(new Hex(-3, -14)),
    new GrassTile(new Hex(-3, -13)),
    new GrassTile(new Hex(-3, -12)),
    new GrassTile(new Hex(-3, -11)),
    new GrassTile(new Hex(-3, -10)),
    new GrassTile(new Hex(-3, -9)),
    new GrassTile(new Hex(-3, -8)),
    new GrassTile(new Hex(-3, -7)),
    new GrassTile(new Hex(-3, -6)),
    new GrassTile(new Hex(-3, -5)),
    new GrassTile(new Hex(-3, -4)),
    new GrassTile(new Hex(-3, -3)),
    new GrassTile(new Hex(-3, -2)),
    new GrassTile(new Hex(-3, -1)),
    new GrassTile(new Hex(-3, 0)),
    new GrassTile(new Hex(-3, 1)),
    new GrassTile(new Hex(-3, 2)),
    new GrassTile(new Hex(-3, 3)),
    new GrassTile(new Hex(-3, 4)),
    new GrassTile(new Hex(-3, 5)),
    new GrassTile(new Hex(-3, 6)),
    new GrassTile(new Hex(-3, 7)),
    new GrassTile(new Hex(-3, 8)),
    new GrassTile(new Hex(-3, 9)),
    new GrassTile(new Hex(-3, 10)),
    new GrassTile(new Hex(-3, 11)),
    new GrassTile(new Hex(-3, 12)),
    new GrassTile(new Hex(-3, 13)),
    new GrassTile(new Hex(-3, 14)),
    new GrassTile(new Hex(-3, 15)),
    new SteelTile(new Hex(-3, 16)),
    new SteelTile(new Hex(-3, 17)),
    new SteelTile(new Hex(-3, 18)),
    new SteelTile(new Hex(-3, 19)),
    new HillTile(new Hex(-3, 20)),
    new HillTile(new Hex(-2, -18)),
    new GrassTile(new Hex(-2, -17)),
    new GrassTile(new Hex(-2, -16)),
    new GrassTile(new Hex(-2, -15)),
    new GrassTile(new Hex(-2, -14)),
    new GrassTile(new Hex(-2, -13)),
    new GrassTile(new Hex(-2, -12)),
    new GrassTile(new Hex(-2, -11)),
    new GrassTile(new Hex(-2, -10)),
    new GrassTile(new Hex(-2, -9)),
    new GrassTile(new Hex(-2, -8)),
    new GrassTile(new Hex(-2, -7)),
    new GrassTile(new Hex(-2, -6)),
    new GrassTile(new Hex(-2, -5)),
    new GrassTile(new Hex(-2, -4)),
    new GrassTile(new Hex(-2, -3)),
    new GrassTile(new Hex(-2, -2)),
    new GrassTile(new Hex(-2, -1)),
    new GrassTile(new Hex(-2, 0)),
    new GrassTile(new Hex(-2, 1)),
    new GrassTile(new Hex(-2, 2)),
    new GrassTile(new Hex(-2, 3)),
    new GrassTile(new Hex(-2, 4)),
    new GrassTile(new Hex(-2, 5)),
    new GrassTile(new Hex(-2, 6)),
    new GrassTile(new Hex(-2, 7)),
    new GrassTile(new Hex(-2, 8)),
    new GrassTile(new Hex(-2, 9)),
    new GrassTile(new Hex(-2, 10)),
    new GrassTile(new Hex(-2, 11)),
    new GrassTile(new Hex(-2, 12)),
    new GrassTile(new Hex(-2, 13)),
    new GrassTile(new Hex(-2, 14)),
    new SteelTile(new Hex(-2, 15)),
    new SteelTile(new Hex(-2, 16)),
    new SteelTile(new Hex(-2, 17)),
    new SteelTile(new Hex(-2, 18)),
    new SteelTile(new Hex(-2, 19)),
    new HillTile(new Hex(-2, 20)),
    new RockTile(new Hex(-1, -19)),
    new RockTile(new Hex(-1, -18)),
    new RockTile(new Hex(-1, -17)),
    new RockTile(new Hex(-1, -16)),
    new RockTile(new Hex(-1, -15)),
    new RockTile(new Hex(-1, -14)),
    new RockTile(new Hex(-1, -13)),
    new RockTile(new Hex(-1, -12)),
    new RockTile(new Hex(-1, -11)),
    new RockTile(new Hex(-1, -10)),
    new RockTile(new Hex(-1, -9)),
    new RockTile(new Hex(-1, -8)),
    new RockTile(new Hex(-1, -7)),
    new RockTile(new Hex(-1, -6)),
    new RockTile(new Hex(-1, -5)),
    new RockTile(new Hex(-1, -4)),
    new RockTile(new Hex(-1, -3)),
    new RockTile(new Hex(-1, -2)),
    new RockTile(new Hex(-1, -1)),
    new RockTile(new Hex(-1, 0)),
    new RockTile(new Hex(-1, 1)),
    new RockTile(new Hex(-1, 2)),
    new RockTile(new Hex(-1, 3)),
    new RockTile(new Hex(-1, 4)),
    new RockTile(new Hex(-1, 5)),
    new RockTile(new Hex(-1, 6)),
    new RockTile(new Hex(-1, 7)),
    new RockTile(new Hex(-1, 8)),
    new RockTile(new Hex(-1, 9)),
    new RockTile(new Hex(-1, 10)),
    new RockTile(new Hex(-1, 11)),
    new RockTile(new Hex(-1, 12)),
    new RockTile(new Hex(-1, 13)),
    new SteelTile(new Hex(-1, 14)),
    new SteelTile(new Hex(-1, 15)),
    new SteelTile(new Hex(-1, 16)),
    new SteelTile(new Hex(-1, 17)),
    new SteelTile(new Hex(-1, 18)),
    new SteelTile(new Hex(-1, 19)),
    new HillTile(new Hex(-1, 20)),
    new RockTile(new Hex(0, -20)),
    new RockTile(new Hex(0, -19)),
    new RockTile(new Hex(0, -18)),
    new RockTile(new Hex(0, -17)),
    new RockTile(new Hex(0, -16)),
    new RockTile(new Hex(0, -15)),
    new RockTile(new Hex(0, -14)),
    new RockTile(new Hex(0, -13)),
    new RockTile(new Hex(0, -12)),
    new RockTile(new Hex(0, -11)),
    new RockTile(new Hex(0, -10)),
    new RockTile(new Hex(0, -9)),
    new RockTile(new Hex(0, -8)),
    new RockTile(new Hex(0, -7)),
    new RockTile(new Hex(0, -6)),
    new RockTile(new Hex(0, -5)),
    new RockTile(new Hex(0, -4)),
    new RockTile(new Hex(0, -3)),
    new RockTile(new Hex(0, -2)),
    new RockTile(new Hex(0, -1)),
    new RockTile(new Hex(0, 0)),
    new RockTile(new Hex(0, 1)),
    new RockTile(new Hex(0, 2)),
    new RockTile(new Hex(0, 3)),
    new RockTile(new Hex(0, 4)),
    new RockTile(new Hex(0, 5)),
    new RockTile(new Hex(0, 6)),
    new RockTile(new Hex(0, 7)),
    new RockTile(new Hex(0, 8)),
    new RockTile(new Hex(0, 9)),
    new RockTile(new Hex(0, 10)),
    new RockTile(new Hex(0, 11)),
    new RockTile(new Hex(0, 12)),
    new SteelTile(new Hex(0, 13)),
    new SteelTile(new Hex(0, 14)),
    new SteelTile(new Hex(0, 15)),
    new SteelTile(new Hex(0, 16)),
    new SteelTile(new Hex(0, 17)),
    new SteelTile(new Hex(0, 18)),
    new SteelTile(new Hex(0, 19)),
    new HillTile(new Hex(0, 20)),
    new RockTile(new Hex(1, -20)),
    new RockTile(new Hex(1, -19)),
    new RockTile(new Hex(1, -18)),
    new RockTile(new Hex(1, -17)),
    new RockTile(new Hex(1, -16)),
    new RockTile(new Hex(1, -15)),
    new RockTile(new Hex(1, -14)),
    new RockTile(new Hex(1, -13)),
    new RockTile(new Hex(1, -12)),
    new RockTile(new Hex(1, -11)),
    new RockTile(new Hex(1, -10)),
    new RockTile(new Hex(1, -9)),
    new RockTile(new Hex(1, -8)),
    new RockTile(new Hex(1, -7)),
    new RockTile(new Hex(1, -6)),
    new RockTile(new Hex(1, -5)),
    new RockTile(new Hex(1, -4)),
    new RockTile(new Hex(1, -3)),
    new RockTile(new Hex(1, -2)),
    new RockTile(new Hex(1, -1)),
    new RockTile(new Hex(1, 0)),
    new RockTile(new Hex(1, 1)),
    new RockTile(new Hex(1, 2)),
    new RockTile(new Hex(1, 3)),
    new RockTile(new Hex(1, 4)),
    new RockTile(new Hex(1, 5)),
    new RockTile(new Hex(1, 6)),
    new RockTile(new Hex(1, 7)),
    new RockTile(new Hex(1, 8)),
    new RockTile(new Hex(1, 9)),
    new RockTile(new Hex(1, 10)),
    new RockTile(new Hex(1, 11)),
    new RockTile(new Hex(1, 12)),
    new SteelTile(new Hex(1, 13)),
    new SteelTile(new Hex(1, 14)),
    new SteelTile(new Hex(1, 15)),
    new SteelTile(new Hex(1, 16)),
    new SteelTile(new Hex(1, 17)),
    new SteelTile(new Hex(1, 18)),
    new HillTile(new Hex(1, 19)),
    new HillTile(new Hex(2, -20)),
    new GrassTile(new Hex(2, -19)),
    new GrassTile(new Hex(2, -18)),
    new GrassTile(new Hex(2, -17)),
    new GrassTile(new Hex(2, -16)),
    new GrassTile(new Hex(2, -15)),
    new GrassTile(new Hex(2, -14)),
    new GrassTile(new Hex(2, -13)),
    new GrassTile(new Hex(2, -12)),
    new GrassTile(new Hex(2, -11)),
    new GrassTile(new Hex(2, -10)),
    new GrassTile(new Hex(2, -9)),
    new GrassTile(new Hex(2, -8)),
    new GrassTile(new Hex(2, -7)),
    new GrassTile(new Hex(2, -6)),
    new GrassTile(new Hex(2, -5)),
    new GrassTile(new Hex(2, -4)),
    new GrassTile(new Hex(2, -3)),
    new GrassTile(new Hex(2, -2)),
    new GrassTile(new Hex(2, -1)),
    new GrassTile(new Hex(2, 0)),
    new GrassTile(new Hex(2, 1)),
    new GrassTile(new Hex(2, 2)),
    new GrassTile(new Hex(2, 3)),
    new GrassTile(new Hex(2, 4)),
    new GrassTile(new Hex(2, 5)),
    new GrassTile(new Hex(2, 6)),
    new GrassTile(new Hex(2, 7)),
    new GrassTile(new Hex(2, 8)),
    new GrassTile(new Hex(2, 9)),
    new GrassTile(new Hex(2, 10)),
    new GrassTile(new Hex(2, 11)),
    new GrassTile(new Hex(2, 12)),
    new SteelTile(new Hex(2, 13)),
    new SteelTile(new Hex(2, 14)),
    new SteelTile(new Hex(2, 15)),
    new SteelTile(new Hex(2, 16)),
    new SteelTile(new Hex(2, 17)),
    new HillTile(new Hex(2, 18)),
    new HillTile(new Hex(3, -20)),
    new GrassTile(new Hex(3, -19)),
    new GrassTile(new Hex(3, -18)),
    new GrassTile(new Hex(3, -17)),
    new GrassTile(new Hex(3, -16)),
    new GrassTile(new Hex(3, -15)),
    new GrassTile(new Hex(3, -14)),
    new GrassTile(new Hex(3, -13)),
    new GrassTile(new Hex(3, -12)),
    new GrassTile(new Hex(3, -11)),
    new GrassTile(new Hex(3, -10)),
    new GrassTile(new Hex(3, -9)),
    new GrassTile(new Hex(3, -8)),
    new GrassTile(new Hex(3, -7)),
    new GrassTile(new Hex(3, -6)),
    new GrassTile(new Hex(3, -5)),
    new WaterTile(new Hex(3, -4)),
    new WaterTile(new Hex(3, -3)),
    new WaterTile(new Hex(3, -2)),
    new WaterTile(new Hex(3, -1)),
    new GrassTile(new Hex(3, 0)),
    new GrassTile(new Hex(3, 1)),
    new GrassTile(new Hex(3, 2)),
    new GrassTile(new Hex(3, 3)),
    new GrassTile(new Hex(3, 4)),
    new GrassTile(new Hex(3, 5)),
    new GrassTile(new Hex(3, 6)),
    new GrassTile(new Hex(3, 7)),
    new GrassTile(new Hex(3, 8)),
    new GrassTile(new Hex(3, 9)),
    new GrassTile(new Hex(3, 10)),
    new GrassTile(new Hex(3, 11)),
    new GrassTile(new Hex(3, 12)),
    new SteelTile(new Hex(3, 13)),
    new SteelTile(new Hex(3, 14)),
    new SteelTile(new Hex(3, 15)),
    new SteelTile(new Hex(3, 16)),
    new HillTile(new Hex(3, 17)),
    new HillTile(new Hex(4, -20)),
    new GrassTile(new Hex(4, -19)),
    new WaterTile(new Hex(4, -18)),
    new WaterTile(new Hex(4, -17)),
    new WaterTile(new Hex(4, -16)),
    new GrassTile(new Hex(4, -15)),
    new GrassTile(new Hex(4, -14)),
    new GrassTile(new Hex(4, -13)),
    new GrassTile(new Hex(4, -12)),
    new GrassTile(new Hex(4, -11)),
    new GrassTile(new Hex(4, -10)),
    new GrassTile(new Hex(4, -9)),
    new GrassTile(new Hex(4, -8)),
    new GrassTile(new Hex(4, -7)),
    new WaterTile(new Hex(4, -6)),
    new WaterTile(new Hex(4, -5)),
    new WaterTile(new Hex(4, -4)),
    new WaterTile(new Hex(4, -3)),
    new WaterTile(new Hex(4, -2)),
    new WaterTile(new Hex(4, -1)),
    new GrassTile(new Hex(4, 0)),
    new GrassTile(new Hex(4, 1)),
    new GrassTile(new Hex(4, 2)),
    new GrassTile(new Hex(4, 3)),
    new GrassTile(new Hex(4, 4)),
    new GrassTile(new Hex(4, 5)),
    new GrassTile(new Hex(4, 6)),
    new GrassTile(new Hex(4, 7)),
    new GrassTile(new Hex(4, 8)),
    new GrassTile(new Hex(4, 9)),
    new GrassTile(new Hex(4, 10)),
    new GrassTile(new Hex(4, 11)),
    new GrassTile(new Hex(4, 12)),
    new RockTile(new Hex(4, 13)),
    new RockTile(new Hex(4, 14)),
    new RockTile(new Hex(4, 15)),
    new HillTile(new Hex(4, 16)),
    new HillTile(new Hex(5, -20)),
    new WaterTile(new Hex(5, -19)),
    new WaterTile(new Hex(5, -18)),
    new WaterTile(new Hex(5, -17)),
    new WaterTile(new Hex(5, -16)),
    new GrassTile(new Hex(5, -15)),
    new GrassTile(new Hex(5, -14)),
    new GrassTile(new Hex(5, -13)),
    new GrassTile(new Hex(5, -12)),
    new GrassTile(new Hex(5, -11)),
    new GrassTile(new Hex(5, -10)),
    new GrassTile(new Hex(5, -9)),
    new GrassTile(new Hex(5, -8)),
    new WaterTile(new Hex(5, -7)),
    new WaterTile(new Hex(5, -6)),
    new GrassTile(new Hex(5, -5)),
    new GrassTile(new Hex(5, -4)),
    new GrassTile(new Hex(5, -3)),
    new WaterTile(new Hex(5, -2)),
    new WaterTile(new Hex(5, -1)),
    new GrassTile(new Hex(5, 0)),
    new GrassTile(new Hex(5, 1)),
    new GrassTile(new Hex(5, 2)),
    new GrassTile(new Hex(5, 3)),
    new GrassTile(new Hex(5, 4)),
    new GrassTile(new Hex(5, 5)),
    new GrassTile(new Hex(5, 6)),
    new GrassTile(new Hex(5, 7)),
    new GrassTile(new Hex(5, 8)),
    new GrassTile(new Hex(5, 9)),
    new GrassTile(new Hex(5, 10)),
    new GrassTile(new Hex(5, 11)),
    new RockTile(new Hex(5, 12)),
    new RockTile(new Hex(5, 13)),
    new RockTile(new Hex(5, 14)),
    new HillTile(new Hex(5, 15)),
    new WaterTile(new Hex(6, -20)),
    new WaterTile(new Hex(6, -19)),
    new GrassTile(new Hex(6, -18)),
    new WaterTile(new Hex(6, -17)),
    new WaterTile(new Hex(6, -16)),
    new GrassTile(new Hex(6, -15)),
    new GrassTile(new Hex(6, -14)),
    new GrassTile(new Hex(6, -13)),
    new GrassTile(new Hex(6, -12)),
    new GrassTile(new Hex(6, -11)),
    new GrassTile(new Hex(6, -10)),
    new GrassTile(new Hex(6, -9)),
    new WaterTile(new Hex(6, -8)),
    new WaterTile(new Hex(6, -7)),
    new GrassTile(new Hex(6, -6)),
    new GrassTile(new Hex(6, -5)),
    new GrassTile(new Hex(6, -4)),
    new GrassTile(new Hex(6, -3)),
    new WaterTile(new Hex(6, -2)),
    new WaterTile(new Hex(6, -1)),
    new GrassTile(new Hex(6, 0)),
    new GrassTile(new Hex(6, 1)),
    new GrassTile(new Hex(6, 2)),
    new GrassTile(new Hex(6, 3)),
    new GrassTile(new Hex(6, 4)),
    new GrassTile(new Hex(6, 5)),
    new GrassTile(new Hex(6, 6)),
    new GrassTile(new Hex(6, 7)),
    new GrassTile(new Hex(6, 8)),
    new GrassTile(new Hex(6, 9)),
    new GrassTile(new Hex(6, 10)),
    new RockTile(new Hex(6, 11)),
    new RockTile(new Hex(6, 12)),
    new RockTile(new Hex(6, 13)),
    new HillTile(new Hex(6, 14)),
    new WaterTile(new Hex(7, -20)),
    new GrassTile(new Hex(7, -19)),
    new GrassTile(new Hex(7, -18)),
    new WaterTile(new Hex(7, -17)),
    new WaterTile(new Hex(7, -16)),
    new GrassTile(new Hex(7, -15)),
    new GrassTile(new Hex(7, -14)),
    new GrassTile(new Hex(7, -13)),
    new GrassTile(new Hex(7, -12)),
    new GrassTile(new Hex(7, -11)),
    new GrassTile(new Hex(7, -10)),
    new WaterTile(new Hex(7, -9)),
    new WaterTile(new Hex(7, -8)),
    new GrassTile(new Hex(7, -7)),
    new GrassTile(new Hex(7, -6)),
    new GrassTile(new Hex(7, -5)),
    new GrassTile(new Hex(7, -4)),
    new GrassTile(new Hex(7, -3)),
    new WaterTile(new Hex(7, -2)),
    new WaterTile(new Hex(7, -1)),
    new GrassTile(new Hex(7, 0)),
    new GrassTile(new Hex(7, 1)),
    new GrassTile(new Hex(7, 2)),
    new GrassTile(new Hex(7, 3)),
    new GrassTile(new Hex(7, 4)),
    new GrassTile(new Hex(7, 5)),
    new GrassTile(new Hex(7, 6)),
    new GrassTile(new Hex(7, 7)),
    new GrassTile(new Hex(7, 8)),
    new GrassTile(new Hex(7, 9)),
    new RockTile(new Hex(7, 10)),
    new RockTile(new Hex(7, 11)),
    new RockTile(new Hex(7, 12)),
    new HillTile(new Hex(7, 13)),
    new HillTile(new Hex(8, -20)),
    new GrassTile(new Hex(8, -19)),
    new GrassTile(new Hex(8, -18)),
    new WaterTile(new Hex(8, -17)),
    new WaterTile(new Hex(8, -16)),
    new GrassTile(new Hex(8, -15)),
    new GrassTile(new Hex(8, -14)),
    new GrassTile(new Hex(8, -13)),
    new GrassTile(new Hex(8, -12)),
    new GrassTile(new Hex(8, -11)),
    new WaterTile(new Hex(8, -10)),
    new WaterTile(new Hex(8, -9)),
    new GrassTile(new Hex(8, -8)),
    new GrassTile(new Hex(8, -7)),
    new GrassTile(new Hex(8, -6)),
    new GrassTile(new Hex(8, -5)),
    new GrassTile(new Hex(8, -4)),
    new GrassTile(new Hex(8, -3)),
    new WaterTile(new Hex(8, -2)),
    new WaterTile(new Hex(8, -1)),
    new GrassTile(new Hex(8, 0)),
    new GrassTile(new Hex(8, 1)),
    new GrassTile(new Hex(8, 2)),
    new GrassTile(new Hex(8, 3)),
    new GrassTile(new Hex(8, 4)),
    new GrassTile(new Hex(8, 5)),
    new GrassTile(new Hex(8, 6)),
    new GrassTile(new Hex(8, 7)),
    new GrassTile(new Hex(8, 8)),
    new RockTile(new Hex(8, 9)),
    new RockTile(new Hex(8, 10)),
    new RockTile(new Hex(8, 11)),
    new HillTile(new Hex(8, 12)),
    new HillTile(new Hex(9, -20)),
    new GrassTile(new Hex(9, -19)),
    new GrassTile(new Hex(9, -18)),
    new WaterTile(new Hex(9, -17)),
    new WaterTile(new Hex(9, -16)),
    new WaterTile(new Hex(9, -15)),
    new WaterTile(new Hex(9, -14)),
    new WaterTile(new Hex(9, -13)),
    new WaterTile(new Hex(9, -12)),
    new WaterTile(new Hex(9, -11)),
    new WaterTile(new Hex(9, -10)),
    new GrassTile(new Hex(9, -9)),
    new GrassTile(new Hex(9, -8)),
    new GrassTile(new Hex(9, -7)),
    new GrassTile(new Hex(9, -6)),
    new GrassTile(new Hex(9, -5)),
    new GrassTile(new Hex(9, -4)),
    new GrassTile(new Hex(9, -3)),
    new WaterTile(new Hex(9, -2)),
    new WaterTile(new Hex(9, -1)),
    new GrassTile(new Hex(9, 0)),
    new GrassTile(new Hex(9, 1)),
    new GrassTile(new Hex(9, 2)),
    new GrassTile(new Hex(9, 3)),
    new GrassTile(new Hex(9, 4)),
    new GrassTile(new Hex(9, 5)),
    new GrassTile(new Hex(9, 6)),
    new GrassTile(new Hex(9, 7)),
    new RockTile(new Hex(9, 8)),
    new RockTile(new Hex(9, 9)),
    new RockTile(new Hex(9, 10)),
    new HillTile(new Hex(9, 11)),
    new HillTile(new Hex(10, -20)),
    new GrassTile(new Hex(10, -19)),
    new GrassTile(new Hex(10, -18)),
    new WaterTile(new Hex(10, -17)),
    new WaterTile(new Hex(10, -16)),
    new WaterTile(new Hex(10, -15)),
    new WaterTile(new Hex(10, -14)),
    new WaterTile(new Hex(10, -13)),
    new WaterTile(new Hex(10, -12)),
    new WaterTile(new Hex(10, -11)),
    new GrassTile(new Hex(10, -10)),
    new GrassTile(new Hex(10, -9)),
    new GrassTile(new Hex(10, -8)),
    new GrassTile(new Hex(10, -7)),
    new GrassTile(new Hex(10, -6)),
    new GrassTile(new Hex(10, -5)),
    new GrassTile(new Hex(10, -4)),
    new GrassTile(new Hex(10, -3)),
    new WaterTile(new Hex(10, -2)),
    new WaterTile(new Hex(10, -1)),
    new WaterTile(new Hex(10, 0)),
    new GrassTile(new Hex(10, 1)),
    new GrassTile(new Hex(10, 2)),
    new GrassTile(new Hex(10, 3)),
    new GrassTile(new Hex(10, 4)),
    new GrassTile(new Hex(10, 5)),
    new GrassTile(new Hex(10, 6)),
    new RockTile(new Hex(10, 7)),
    new RockTile(new Hex(10, 8)),
    new RockTile(new Hex(10, 9)),
    new HillTile(new Hex(10, 10)),
    new HillTile(new Hex(11, -20)),
    new GrassTile(new Hex(11, -19)),
    new GrassTile(new Hex(11, -18)),
    new GrassTile(new Hex(11, -17)),
    new GrassTile(new Hex(11, -16)),
    new GrassTile(new Hex(11, -15)),
    new GrassTile(new Hex(11, -14)),
    new GrassTile(new Hex(11, -13)),
    new GrassTile(new Hex(11, -12)),
    new GrassTile(new Hex(11, -11)),
    new GrassTile(new Hex(11, -10)),
    new GrassTile(new Hex(11, -9)),
    new GrassTile(new Hex(11, -8)),
    new GrassTile(new Hex(11, -7)),
    new GrassTile(new Hex(11, -6)),
    new GrassTile(new Hex(11, -5)),
    new GrassTile(new Hex(11, -4)),
    new GrassTile(new Hex(11, -3)),
    new GrassTile(new Hex(11, -2)),
    new WaterTile(new Hex(11, -1)),
    new WaterTile(new Hex(11, 0)),
    new GrassTile(new Hex(11, 1)),
    new GrassTile(new Hex(11, 2)),
    new GrassTile(new Hex(11, 3)),
    new GrassTile(new Hex(11, 4)),
    new GrassTile(new Hex(11, 5)),
    new RockTile(new Hex(11, 6)),
    new RockTile(new Hex(11, 7)),
    new RockTile(new Hex(11, 8)),
    new HillTile(new Hex(11, 9)),
    new HillTile(new Hex(12, -20)),
    new GrassTile(new Hex(12, -19)),
    new GrassTile(new Hex(12, -18)),
    new GrassTile(new Hex(12, -17)),
    new GrassTile(new Hex(12, -16)),
    new GrassTile(new Hex(12, -15)),
    new GrassTile(new Hex(12, -14)),
    new GrassTile(new Hex(12, -13)),
    new GrassTile(new Hex(12, -12)),
    new GrassTile(new Hex(12, -11)),
    new GrassTile(new Hex(12, -10)),
    new GrassTile(new Hex(12, -9)),
    new GrassTile(new Hex(12, -8)),
    new GrassTile(new Hex(12, -7)),
    new GrassTile(new Hex(12, -6)),
    new GrassTile(new Hex(12, -5)),
    new GrassTile(new Hex(12, -4)),
    new GrassTile(new Hex(12, -3)),
    new GrassTile(new Hex(12, -2)),
    new WaterTile(new Hex(12, -1)),
    new WaterTile(new Hex(12, 0)),
    new GrassTile(new Hex(12, 1)),
    new GrassTile(new Hex(12, 2)),
    new GrassTile(new Hex(12, 3)),
    new GrassTile(new Hex(12, 4)),
    new RockTile(new Hex(12, 5)),
    new RockTile(new Hex(12, 6)),
    new RockTile(new Hex(12, 7)),
    new HillTile(new Hex(12, 8)),
    new HillTile(new Hex(13, -20)),
    new GrassTile(new Hex(13, -19)),
    new GrassTile(new Hex(13, -18)),
    new GrassTile(new Hex(13, -17)),
    new GrassTile(new Hex(13, -16)),
    new GrassTile(new Hex(13, -15)),
    new GrassTile(new Hex(13, -14)),
    new GrassTile(new Hex(13, -13)),
    new GrassTile(new Hex(13, -12)),
    new GrassTile(new Hex(13, -11)),
    new GrassTile(new Hex(13, -10)),
    new GrassTile(new Hex(13, -9)),
    new GrassTile(new Hex(13, -8)),
    new GrassTile(new Hex(13, -7)),
    new GrassTile(new Hex(13, -6)),
    new GrassTile(new Hex(13, -5)),
    new GrassTile(new Hex(13, -4)),
    new GrassTile(new Hex(13, -3)),
    new GrassTile(new Hex(13, -2)),
    new WaterTile(new Hex(13, -1)),
    new WaterTile(new Hex(13, 0)),
    new GrassTile(new Hex(13, 1)),
    new GrassTile(new Hex(13, 2)),
    new GrassTile(new Hex(13, 3)),
    new RockTile(new Hex(13, 4)),
    new RockTile(new Hex(13, 5)),
    new RockTile(new Hex(13, 6)),
    new HillTile(new Hex(13, 7)),
    new HillTile(new Hex(14, -20)),
    new GrassTile(new Hex(14, -19)),
    new GrassTile(new Hex(14, -18)),
    new GrassTile(new Hex(14, -17)),
    new GrassTile(new Hex(14, -16)),
    new GrassTile(new Hex(14, -15)),
    new GrassTile(new Hex(14, -14)),
    new GrassTile(new Hex(14, -13)),
    new GrassTile(new Hex(14, -12)),
    new GrassTile(new Hex(14, -11)),
    new GrassTile(new Hex(14, -10)),
    new GrassTile(new Hex(14, -9)),
    new GrassTile(new Hex(14, -8)),
    new GrassTile(new Hex(14, -7)),
    new GrassTile(new Hex(14, -6)),
    new GrassTile(new Hex(14, -5)),
    new GrassTile(new Hex(14, -4)),
    new GrassTile(new Hex(14, -3)),
    new GrassTile(new Hex(14, -2)),
    new WaterTile(new Hex(14, -1)),
    new WaterTile(new Hex(14, 0)),
    new GrassTile(new Hex(14, 1)),
    new GrassTile(new Hex(14, 2)),
    new RockTile(new Hex(14, 3)),
    new RockTile(new Hex(14, 4)),
    new RockTile(new Hex(14, 5)),
    new HillTile(new Hex(14, 6)),
    new HillTile(new Hex(15, -20)),
    new GrassTile(new Hex(15, -19)),
    new GrassTile(new Hex(15, -18)),
    new GrassTile(new Hex(15, -17)),
    new GrassTile(new Hex(15, -16)),
    new GrassTile(new Hex(15, -15)),
    new GrassTile(new Hex(15, -14)),
    new GrassTile(new Hex(15, -13)),
    new GrassTile(new Hex(15, -12)),
    new GrassTile(new Hex(15, -11)),
    new GrassTile(new Hex(15, -10)),
    new GrassTile(new Hex(15, -9)),
    new GrassTile(new Hex(15, -8)),
    new GrassTile(new Hex(15, -7)),
    new GrassTile(new Hex(15, -6)),
    new GrassTile(new Hex(15, -5)),
    new GrassTile(new Hex(15, -4)),
    new GrassTile(new Hex(15, -3)),
    new GrassTile(new Hex(15, -2)),
    new WaterTile(new Hex(15, -1)),
    new WaterTile(new Hex(15, 0)),
    new GrassTile(new Hex(15, 1)),
    new RockTile(new Hex(15, 2)),
    new RockTile(new Hex(15, 3)),
    new RockTile(new Hex(15, 4)),
    new HillTile(new Hex(15, 5)),
    new HillTile(new Hex(16, -20)),
    new GrassTile(new Hex(16, -19)),
    new GrassTile(new Hex(16, -18)),
    new GrassTile(new Hex(16, -17)),
    new GrassTile(new Hex(16, -16)),
    new GrassTile(new Hex(16, -15)),
    new GrassTile(new Hex(16, -14)),
    new GrassTile(new Hex(16, -13)),
    new GrassTile(new Hex(16, -12)),
    new GrassTile(new Hex(16, -11)),
    new GrassTile(new Hex(16, -10)),
    new GrassTile(new Hex(16, -9)),
    new GrassTile(new Hex(16, -8)),
    new GrassTile(new Hex(16, -7)),
    new GrassTile(new Hex(16, -6)),
    new GrassTile(new Hex(16, -5)),
    new GrassTile(new Hex(16, -4)),
    new GrassTile(new Hex(16, -3)),
    new GrassTile(new Hex(16, -2)),
    new WaterTile(new Hex(16, -1)),
    new WaterTile(new Hex(16, 0)),
    new GrassTile(new Hex(16, 1)),
    new RockTile(new Hex(16, 2)),
    new RockTile(new Hex(16, 3)),
    new RockTile(new Hex(16, 4)),
    new HillTile(new Hex(17, -20)),
    new GrassTile(new Hex(17, -19)),
    new GrassTile(new Hex(17, -18)),
    new GrassTile(new Hex(17, -17)),
    new GrassTile(new Hex(17, -16)),
    new GrassTile(new Hex(17, -15)),
    new GrassTile(new Hex(17, -14)),
    new GrassTile(new Hex(17, -13)),
    new GrassTile(new Hex(17, -12)),
    new GrassTile(new Hex(17, -11)),
    new GrassTile(new Hex(17, -10)),
    new GrassTile(new Hex(17, -9)),
    new GrassTile(new Hex(17, -8)),
    new GrassTile(new Hex(17, -7)),
    new GrassTile(new Hex(17, -6)),
    new GrassTile(new Hex(17, -5)),
    new GrassTile(new Hex(17, -4)),
    new GrassTile(new Hex(17, -3)),
    new GrassTile(new Hex(17, -2)),
    new WaterTile(new Hex(17, -1)),
    new WaterTile(new Hex(17, 0)),
    new GrassTile(new Hex(17, 1)),
    new RockTile(new Hex(17, 2)),
    new RockTile(new Hex(17, 3)),
    new HillTile(new Hex(18, -20)),
    new GrassTile(new Hex(18, -19)),
    new GrassTile(new Hex(18, -18)),
    new GrassTile(new Hex(18, -17)),
    new GrassTile(new Hex(18, -16)),
    new GrassTile(new Hex(18, -15)),
    new GrassTile(new Hex(18, -14)),
    new GrassTile(new Hex(18, -13)),
    new GrassTile(new Hex(18, -12)),
    new GrassTile(new Hex(18, -11)),
    new GrassTile(new Hex(18, -10)),
    new GrassTile(new Hex(18, -9)),
    new GrassTile(new Hex(18, -8)),
    new GrassTile(new Hex(18, -7)),
    new GrassTile(new Hex(18, -6)),
    new GrassTile(new Hex(18, -5)),
    new GrassTile(new Hex(18, -4)),
    new GrassTile(new Hex(18, -3)),
    new GrassTile(new Hex(18, -2)),
    new WaterTile(new Hex(18, -1)),
    new WaterTile(new Hex(18, 0)),
    new GrassTile(new Hex(18, 1)),
    new RockTile(new Hex(18, 2)),
    new HillTile(new Hex(19, -20)),
    new GrassTile(new Hex(19, -19)),
    new GrassTile(new Hex(19, -18)),
    new GrassTile(new Hex(19, -17)),
    new GrassTile(new Hex(19, -16)),
    new GrassTile(new Hex(19, -15)),
    new GrassTile(new Hex(19, -14)),
    new GrassTile(new Hex(19, -13)),
    new GrassTile(new Hex(19, -12)),
    new GrassTile(new Hex(19, -11)),
    new GrassTile(new Hex(19, -10)),
    new GrassTile(new Hex(19, -9)),
    new GrassTile(new Hex(19, -8)),
    new GrassTile(new Hex(19, -7)),
    new GrassTile(new Hex(19, -6)),
    new GrassTile(new Hex(19, -5)),
    new GrassTile(new Hex(19, -4)),
    new GrassTile(new Hex(19, -3)),
    new GrassTile(new Hex(19, -2)),
    new WaterTile(new Hex(19, -1)),
    new WaterTile(new Hex(19, 0)),
    new HillTile(new Hex(19, 1)),
    new HillTile(new Hex(20, -20)),
    new HillTile(new Hex(20, -19)),
    new HillTile(new Hex(20, -18)),
    new HillTile(new Hex(20, -17)),
    new HillTile(new Hex(20, -16)),
    new HillTile(new Hex(20, -15)),
    new HillTile(new Hex(20, -14)),
    new HillTile(new Hex(20, -13)),
    new HillTile(new Hex(20, -12)),
    new HillTile(new Hex(20, -11)),
    new HillTile(new Hex(20, -10)),
    new HillTile(new Hex(20, -9)),
    new HillTile(new Hex(20, -8)),
    new HillTile(new Hex(20, -7)),
    new HillTile(new Hex(20, -6)),
    new HillTile(new Hex(20, -5)),
    new HillTile(new Hex(20, -4)),
    new HillTile(new Hex(20, -3)),
    new HillTile(new Hex(20, -2)),
    new WaterTile(new Hex(20, -1)),
    new WaterTile(new Hex(20, 0))
];