import {HexDirection} from '../../utils/Hex';

export enum ResourceType {
    mineral = 'Mineral',
    energy = 'Energy module',
    amo = 'Amo'
}

export enum NodeMode {
    INPUT,
    OUTPUT
}

export class ResourceNode {
    // Whether the node imports or exports resources
    mode: NodeMode;
    // Orientation of the node
    direction: HexDirection;

    // The type of resource in this node (can be undefined)
    resourceType?: ResourceType;
    // Whether the node contains a resource or not (for input nodes only)
    isFull: boolean = false;

    constructor(mode_: NodeMode, direction_: HexDirection) {
        this.mode = mode_;
        this.direction = direction_;
    }

    /** Receive a resource into this node */
    receive: (resource: ResourceType) => boolean = (resource) => {
        // An output node cannot receive a resource
        if (this.mode === NodeMode.OUTPUT) {
            return false;
        }
        // If this node is full, it cannot receive a resource
        if (this.isFull) {
            return false;
        }
        // Receive the resource
        this.resourceType = resource;
        this.isFull = true;
        return true;
    }

    /** Take the resource from this node if possible */
    take : () => boolean = () => {
        if (this.isFull) {
            this.isFull = false;
            return true;
        }
        return false;
    }

    /** Check if this node is compatible with another node for a resource exchange */
    isCompatible: (other: ResourceNode) => boolean = (other) => {
        if (!other) {
            return false;
        }
        // Both nodes need to have oposite modes
        if (other.mode === this.mode) {
            return false;
        }
        // Both nodes need to have an oposite orientation
        if ((other.direction + 3) % 6 !== this.direction) {
            return false;
        }
        return true;
    }

    /** Give a resource from this node to another one */
    giveTo: (target: ResourceNode) => boolean = (target) => {
        // An input node cannot give a resource
        if (this.mode === NodeMode.INPUT) {
            return false;
        }
        // If this node is empty, it cannot give a resource
        if (!this.isFull) {
            return false;
        }
        // If the nodes aren't compatible, cancel the transfer
        if (!this.isCompatible(target)) {
            return false;
        }
        // Give the resource
        this.isFull = false;
        return true;
    }
}