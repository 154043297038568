// Window dimensions
export const GAME_WINDOW_WIDTH = 1100;
export const GAME_WINDOW_HEIGHT = 600;
export const MAP_FRAME_WIDTH = 800;
export const MAP_FRAME_HEIGHT = GAME_WINDOW_HEIGHT;
export const MENU_FRAME_WIDTH = GAME_WINDOW_WIDTH - MAP_FRAME_WIDTH;
export const MENU_FRAME_HEIGHT = GAME_WINDOW_HEIGHT;

// Positioning dimensions
export const TILE_WIDTH = 63;
export const TILE_HEIGHT = 48;

// Selection dimensions
export const TILE_SELECT_WIDTH = 62;
export const TILE_SELECT_HEIGHT = 115;

// Display dimensions
export const TILE_DISPLAY_WIDTH = 88;
export const TILE_DISPLAY_HEIGHT = 176;

// Map dimensions
export const MAP_RADIUS = 20;
const MARGIN = 300;
export const X_DRAG_LIMIT = TILE_WIDTH * MAP_RADIUS - (MAP_FRAME_WIDTH / 2) + MARGIN;
export const Y_DRAG_LIMIT = TILE_HEIGHT * MAP_RADIUS - (MAP_FRAME_HEIGHT / 2) + MARGIN;