import React, {MouseEvent, useCallback, useRef, useState} from 'react';
import {Coordinates} from 'siccinct-game/utils/GameTypes';
import styled from 'styled-components';

type DraggableContainerProps = {
    isMouseDown: boolean
}

const DraggableContainer = styled.div.attrs<DraggableContainerProps>((props) => ({
    style: {
        cursor: props.isMouseDown ? 'grabbing' : 'unset'
    }
}))<DraggableContainerProps>`
    width: 100%;
    height: 100%;
    transition: .6s;
`;

type ZoomableContainerProps = {
    zoom: number
}

const ZoomableContainer = styled.div.attrs<ZoomableContainerProps>((props) => ({
    style: {
        transform: `scale(${props.zoom})`
    }
}))<ZoomableContainerProps>`
    width: 100%;
    height: 100%;
    transform-origin: center;
    transition: .4s;
`;

type MapProps = {
    position: Coordinates
}

const Map = styled.div.attrs<MapProps>((props) => ({
    style: {
        translate: `${-props.position.x}px ${-props.position.y}px`
    }
}))<MapProps>`
    width: 100%;
    height: 100%;
    transition: translate .1s;
`;

type DraggableProps = {
    position: Coordinates,
    zoom: number,
    updatePos: (pos: Coordinates) => void
};

export const DraggableMap: React.FC<DraggableProps> = ({
    children, position, zoom, updatePos
}) => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef<Coordinates>({
        x: 0,
        y: 0
    });

    const handleDragStart = (e: MouseEvent) => {
        setIsMouseDown(true);
        mouseCoords.current = {
            x: e.pageX,
            y: e.pageY
        };
    };

    const handleDragEnd = (e: MouseEvent) => {
        setIsMouseDown(false);
    };

    const handleDrag = useCallback((e: MouseEvent) => {
        if (!isMouseDown) {
            return;
        }
        const movement: Coordinates = {
            x: mouseCoords.current.x - e.pageX,
            y: mouseCoords.current.y - e.pageY
        };
        // Smoothen the movements
        if (Math.abs(movement.x) + Math.abs(movement.y) > 40) {
            updatePos(movement);
            mouseCoords.current = {
                x: e.pageX,
                y: e.pageY
            };
        }
    }, [isMouseDown, updatePos]);

    return (
        <DraggableContainer
            className='drag-container'
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDrag}
            isMouseDown={isMouseDown}
        >
            <ZoomableContainer className='zoom-container' zoom={zoom}>
                <Map className='map' position={position}>
                    {children}
                </Map>
            </ZoomableContainer>
        </DraggableContainer>
    );
};