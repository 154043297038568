
export enum HexDirection {
    NORTH = 0,
    NORTH_WEST = 1,
    SOUTH_WEST = 2,
    SOUTH = 3,
    SOUTH_EAST = 4,
    NORTH_EAST = 5
}

export class Hex {
    q: number;
    r: number;
    // Since the coordinates are axial, s is deduced from q and r
    s: number;

    constructor(q_: number, r_: number) {
        this.q = q_;
        this.r = r_;
        this.s = -q_ - r_;
    }

    /** Vectorial addition */
    add(other: Hex): Hex {
        return new Hex(this.q + other.q, this.r + other.r);
    }

    /** Vectorial substraction */
    subtract(other: Hex): Hex {
        return new Hex(this.q - other.q, this.r - other.r);
    }

    /** Vectorial scale */
    scale(factor: number): Hex {
        return new Hex(this.q * factor, this.r * factor);
    }

    /** Vectorial rotation (left) */
    rotateLeft(): Hex {
        return new Hex(-this.s, -this.q);
    }

    /** Vectorial rotation (right) */
    rotateRight(): Hex {
        return new Hex(-this.r, -this.s);
    }

    /** Starts from North and goes clockwise */
    static directions: Hex[] = [
        new Hex(0, -1),
        new Hex(1, -1),
        new Hex(1, 0),
        new Hex(0, 1),
        new Hex(-1, 1),
        new Hex(-1, 0)
    ];

    /**
     * Returns a Hex vector in the specified direction
     * @param direction 0: N, 1: N-W, 2: S-W, 3: S, 4: S-E, 5: N-E
     * @returns A unitary Hex vector
     */
    static direction(direction: HexDirection): Hex {
        return Hex.directions[direction];
    }

    /**
     * Returns the coordinates of the neighbouring Hex in the specified direction
     * @param direction 0: N, 1: N-W, 2: S-W, 3: S, 4: S-E, 5: N-E
     * @returns A neighbouring Hex
     */
    neighbor(direction: HexDirection): Hex {
        return this.add(Hex.direction(direction));
    }

    /** Starts from North-West and goes clockwise */
    static diagonals: Hex[] = [
        new Hex(1, -2),
        new Hex(2, -1),
        new Hex(1, 1),
        new Hex(-1, 2),
        new Hex(-2, 1),
        new Hex(-1, -1)
    ];

    /**
     * Returns the coordinates of the neighbouring Hex in the specified direction
     * @param direction 0: N-W, 1: W, 2: S-W, 3: S-E, 4: E, 5: N-E
     * @returns A neighbouring Hex
     */
    diagonalNeighbor(direction: number): Hex {
        return this.add(Hex.diagonals[direction]);
    }

    /**
     * Returns the distance between this Hex and the origin
     * @returns The distance between this Hex and the origin
     */
    len(): number {
        return (Math.abs(this.q) + Math.abs(this.r) + Math.abs(this.s)) / 2;
    }

    /**
     * Returns the distance between the Hex and another one
     * @param other The other Hex to compare to this
     * @returns The distance between the two Hex
     */
    distance(other: Hex): number {
        return this.subtract(other).len();
    }

    equals(other?: Hex): boolean {
        if (!other) {
            return false;
        }
        return this.q === other.q && this.r === other.r;
    }
}