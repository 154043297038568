import React from 'react';
import styled from 'styled-components';

import {getTilePos, getTileZ} from 'siccinct-game/utils/GameUtils';
import {Hex} from 'siccinct-game/utils/Hex';
import {BaseTile, TileAnimation} from '../sprites/tiles/BaseTile';
import {TILE_DISPLAY_HEIGHT, TILE_DISPLAY_WIDTH, TILE_SELECT_HEIGHT, TILE_SELECT_WIDTH} from '../utils/GameConstants';

type ContainerProps = {
    x: number,
    y: number,
    z: number,
    selected: boolean,
    fixed: boolean
}

const SpriteContainer = styled.div.attrs<ContainerProps>((props) => ({
    style: {
        zIndex: props.z,
        transform: `translate3d(${props.x}px, ${props.y - (props.selected ? 10 : 0)}px, 0)`,
        filter: props.selected ? 'brightness(1.3)' : 'revert-layer',
        pointerEvents: props.fixed ? 'none' : 'all'
    }
}))<ContainerProps>`
    // The selection dimensions of the tiles
    position: absolute;
    width: ${TILE_SELECT_WIDTH}px;
    height: ${TILE_SELECT_HEIGHT}px;
    transition: transform .6s, translate .4s, filter .2s;

    animation: appear .5s ease 0s 1 none;
    @keyframes appear {
        0% {
            opacity: 0;
            translate: 0px 20px;
        }

        100% {
            opacity: 1;
            translate: 0px 0px;
        }
    }

    &:hover {
        filter: brightness(1.3);
        translate: 0px -20px;
    }
`;

const Sprite = styled.div`
    position: absolute;
    // The display dimensions of the tiles
    width: ${TILE_DISPLAY_WIDTH}px;
    height: ${TILE_DISPLAY_HEIGHT}px;
    // Center the sprite on the tile
    top: -60px;
    left: -13px;
    pointer-events: none;
    overflow: hidden;
`;

type SpriteSheetProps = {
    sprite: string,
    animation?: TileAnimation
}

const SpriteSheet = styled.div.attrs<SpriteSheetProps>((props) => ({
    style: {
        background: `url(${props.sprite}) no-repeat no-repeat`,
        animation: props.animation ? `animate ${props.animation.duration}s steps(${props.animation.steps}) infinite` : 'none'
    }
}))<SpriteSheetProps>`
    // The display dimensions of the tiles
    position: absolute;
    background-size: 100%;
    width: ${TILE_DISPLAY_WIDTH * 8}px;
    height: ${TILE_DISPLAY_HEIGHT}px;
    pointer-events: none;

    @keyframes animate {
        from {
            transform: translate3d(0%,0%,0);
        }
        to {
            transform: translate3d(-100%,0%,0);
        }
    }
`;

type DebugProps = {
    debug: boolean
}

const Debug = styled.div.attrs<DebugProps>((props) => ({
    style: {
        display: props.debug ? 'revert' : 'none'
    }
}))<DebugProps>`
    // Use this to display the tile coordinates
    color: red;
    font-weight: bold;
    translate: 32% 355%;
    user-select: none;
`;

type TileProps = {
    debug?: boolean,
    tile: BaseTile,
    selected?: boolean,
    fixed?: boolean,
    select?: (hex: Hex) => void
};

export const Tile: React.FC<TileProps> = ({
    debug = false,
    tile,
    selected = false,
    fixed = false,
    select = () => {},
    ...otherProps
}) => {
    const {hex, sprite} = tile;
    const {x, y} = fixed ? {x: 0, y: 0} : getTilePos(tile);
    const z = fixed ? 0 : getTileZ(tile);

    return (
        <SpriteContainer
            className={'tile-container'}
            x={x}
            y={y}
            z={z}
            selected={selected}
            fixed={fixed}
            onClick={() => select(hex)}
            draggable={false}
            {...otherProps}
        >
            <Sprite className='sprite'>
                <SpriteSheet className='spritesheet pixel-art' animation={tile.animation} sprite={sprite}/>
                <Debug className='debug' debug={debug}>
                    {tile.printKey()}
                </Debug>
                <div className='module' />
                <div className='effect' />
            </Sprite>
        </SpriteContainer>
    );
};