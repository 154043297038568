import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {
    addEventKey,
    changeCharacter,
    getGameState,
    removeEventKey,
    setPosition
} from './layers/0_StateContainer';
import {TitleScreenContainer} from './layers/1_TitleScreenContainer';
import {LoadingScreenContainer, triggerLoading} from './layers/2_LoadingScreenContainer';
import {MenuScreenContainer} from './layers/3_MenuScreenContainer';
import {DialogContainer} from './layers/4_DialogContainer';
import {
    endMapChange,
    getChangingMap,
    isElementUndefined,
    resetZoom,
    setCharacterAttribute,
    setCharacterPosition,
    setMapPosition,
    setSpritePosition,
    zoom
} from './layers/6_MapContainer';
import {
    addPendingEvent,
    getEventOngoing,
    incrementTimeLoop,
    processEvent,
    switchToNextEvent
} from './layers/9_EventContainer';
import {SoundContainer} from './layers/9_SoundContainer';
import {MAPS} from './maps';
import {AreaEffectDescriptor} from './maps/MapTypes';
import {CHARACTERS} from './sprites';
import {EventDescriptor} from './utils/EventTypes';
import {ActionKeys, DirectionKeys, Directions} from './utils/GameConstants';
import {Coordinates, Shape, ShapeTypes} from './utils/GameTypes';
import {
    getActionHitbox,
    getArea,
    getAutomaticEvent,
    getCurrentHitbox,
    getFrictionUpdatedPosition,
    getManualEvent,
    intersect,
    listSpriteEvents
} from './utils/GameUtils';

const Container = styled.div`
    width: var(--game-frame-width);
    min-width: var(--game-frame-width);
    height: var(--game-frame-height);
    min-height: var(--game-frame-height);
    margin: -10px auto;
    outline: 4px solid #fff;
    z-index: 0;
    background: black;
    position: relative;
    overflow: hidden;

    @keyframes loadAnimation {
        from {
            transform: translate3d(0%,0%,0);
        }
        to {
            transform: translate3d(-100%,0%,0);
        }
    }

    .map-frame {
        transition: transform 0.6s;
        z-index: 0;
        .map {
            z-index: 1;
        }
    }

    .dialog-frame {
        z-index: 1001;
        .dialog {
            .text, .choices {
                z-index: 1002;
            }
        }
        .left-sprite, .right-sprite {
            z-index: 1000;
        }
    }

    .event-frame {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1010;
        position: absolute;
    }

    .menu-frame {
        overflow: hidden;
        z-index: 1010;
        position: absolute;
    }
`;

// Game variables
let isGameStarted = false;
export const getGameStarted = () => isGameStarted;
export const startGame = () => {
    isGameStarted = true;
};
let isLoadingTriggered = false;

// Map
let currentArea: AreaEffectDescriptor | undefined;
const frameWidth = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--game-frame-width'),
    10
);
const frameHeight = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--game-frame-height'),
    10
);
const spriteResolution = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--sprite-resolution'),
    10
);
const xOffset = Math.round((frameWidth - spriteResolution) / 2);
const yOffset = Math.round((frameHeight - spriteResolution) / 2);

// Character
let heldDirections: string[] = [];
const speed = 4;
let currentAction = '';
let facing = 'down';
let characterIndex = 0;
const AVAILABLE_CHARACTERS = ['LISA', 'DIEGO', 'BUN', 'TATO', 'GABI'];

// Menu
let isMenuOpen = false;
export const getMenuOpen = () => isMenuOpen;
export const openMenu = () => {
    heldDirections = [];
    currentAction = '';
    isMenuOpen = true;
};
export const closeMenu = () => {
    heldDirections = [];
    currentAction = '';
    isMenuOpen = false;
};

export const startEvent = (event: EventDescriptor) => {
    addPendingEvent(event);
};

export const endEvent = (index?: number) => {
    // Manage the event list
    switchToNextEvent(index);
    // Cleanup the game variables
    currentAction = '';
    heldDirections = [];
    isLoadingTriggered = false;
    // End the map change
    endMapChange();
};

export const clearDirections = () => {
    heldDirections = [];
};

const getEvent = () => {
    // Check for events on the map depending on the character position
    const {currentMap, currentCharacter, currentPosition, currentEventKeys} = getGameState();
    const {events: mapEvents, sprites} = MAPS[currentMap];
    const {hitbox} = CHARACTERS[currentCharacter];
    const currentHitbox = getCurrentHitbox(hitbox, currentPosition);
    const actionHitbox = getActionHitbox(currentHitbox, facing);

    // Check sprite events
    const spriteEvents = listSpriteEvents(sprites);
    const event = getManualEvent(spriteEvents, actionHitbox, currentEventKeys);
    if (event) {
        return event;
    }
    // Check map events
    const mapEvent = getManualEvent(mapEvents, actionHitbox, currentEventKeys);
    return mapEvent;
};

// Process an action key being pressed
const processAction = () => {
    // Immediately reset action to avoid multiple triggers
    currentAction = '';
    // Find a triggerable event
    const event = getEvent();
    if (event) {
        addPendingEvent(event);
        return false;
    }
    // If the action doesn't trigger anything, return true to allow the player to keep moving
    return true;
};

const checkAutomaticEvents = () => {
    const {currentMap, currentCharacter, currentPosition, currentEventKeys} = getGameState();
    // Check for events on the map depending on the character position
    const {events} = MAPS[currentMap];
    const {hitbox} = CHARACTERS[currentCharacter];
    const currentHitbox = getCurrentHitbox(hitbox, currentPosition);
    const event = getAutomaticEvent(events, currentHitbox, currentEventKeys);

    // Add the event to the list
    if (event) {
        addPendingEvent(event);
    }
};

const enterArea = (area: AreaEffectDescriptor) => {
    currentArea = area;
    addEventKey(area.eventKey);
};

const exitArea = (area: AreaEffectDescriptor) => {
    currentArea = undefined;
    removeEventKey(area.eventKey);
};

const checkAreas = () => {
    const {currentMap, currentCharacter, currentPosition} = getGameState();
    // Check for areas on the map depending on the character position
    const {areas} = MAPS[currentMap];
    const {hitbox} = CHARACTERS[currentCharacter];
    const currentHitbox = getCurrentHitbox(hitbox, currentPosition);
    const area = getArea(areas, currentHitbox);

    // Enter the new area
    if (area && !currentArea) {
        enterArea(area);
    }

    // Or exit the previous one
    if (!area && currentArea) {
        exitArea(currentArea);
    }
};

const checkFriction = (p0: Coordinates, p1: Coordinates, wall: Shape) => {
    if (wall.shapeType === ShapeTypes.rectangle) {
        return p1;
    }
    return getFrictionUpdatedPosition(p0, p1, wall);
};

// Update the character position depending on the current movement, walls and sprites
// (If the new position is out of bounds or inside another hitbox, keep previous values)
const checkPosition = (newPosition : Coordinates, allowFriction: boolean = false) => {
    const {currentMap, currentCharacter, currentPosition} = getGameState();
    const {walls, sprites, constants: {width, height}} = MAPS[currentMap];
    const {hitbox} = CHARACTERS[currentCharacter];

    // Check map borders
    if (newPosition.x < -hitbox.start.x) {
        newPosition.x = -hitbox.start.x;
    }
    if (newPosition.x > width - hitbox.end.x) {
        newPosition.x = width - hitbox.end.x;
    }
    if (newPosition.y < -hitbox.start.y) {
        newPosition.y = -hitbox.start.y;
    }
    if (newPosition.y > height - hitbox.end.y) {
        newPosition.y = height - hitbox.end.y;
    }

    const currentHitbox = getCurrentHitbox(hitbox, newPosition);
    // Check walls
    const collisionWall = walls.find((wall) => intersect(wall, currentHitbox));
    if (collisionWall) {
        if (allowFriction) {
            // Try to move along the wall
            const updatedPosition = {...checkFriction(currentPosition, newPosition, collisionWall)};
            if (updatedPosition.x !== newPosition.x || updatedPosition.y !== newPosition.y) {
                return checkPosition(updatedPosition);
            }
        }
        return false;
    }
    // Check sprites
    if (sprites.find((sprite) => {
        const currentSpriteHitbox = getCurrentHitbox(sprite.hitbox, sprite.position);
        return intersect(currentSpriteHitbox, currentHitbox);
    })) { return false; }

    setPosition(newPosition);
    return true;
};

const updateMapPosition = (currentPosition: Coordinates) => {
    const {currentMap} = getGameState();
    const {width, height} = MAPS[currentMap].constants;
    // Process the horizontal offset
    let mapXOffset = 0;
    let withZoom = true;
    if (width <= frameWidth) {
        // If the map is smaller than the frame, center the map
        mapXOffset = Math.round((frameWidth - width) / 2);
    } else {
        // Center the camera on the character
        mapXOffset = xOffset - currentPosition.x;
        // Stay in bounds
        if (mapXOffset > 0) {
            mapXOffset = 0;
            withZoom = false;
        }
        if (mapXOffset < (frameWidth - width)) {
            mapXOffset = frameWidth - width;
            withZoom = false;
        }
    }
    // Process the vertical offset
    let mapYOffset = 0;
    if (height <= frameHeight) {
        // If the map is smaller than the frame, center the map
        mapYOffset = Math.round((frameHeight - height) / 2);
    } else {
        // Center the camera on the character
        mapYOffset = yOffset - currentPosition.y + 50;
        // Stay in bounds
        if (mapYOffset > 0) {
            mapYOffset = 0;
            withZoom = false;
        }
        if (mapYOffset < (frameHeight - height)) {
            mapYOffset = frameHeight - height;
            withZoom = false;
        }
    }

    if (withZoom) {
        zoom(1.3);
    } else {
        resetZoom();
    }

    // The map is positioned in the frame
    setMapPosition({x: mapXOffset, y: mapYOffset});
};

const insertFacingInKeys = () => {
    if (!heldDirections[0]) {
        return;
    }
    // Remove existing facing keys
    for (const [, value] of Object.entries(Directions)) {
        removeEventKey(`facing-${value}`);
    }
    // Add new facing key
    addEventKey(`facing-${heldDirections[0]}`);
};

// Process a movement key being pressed
const processCharacter = () => {
    const {currentPosition} = getGameState();
    // Process the new prosition from the movement
    const newPosition: Coordinates = {...currentPosition};
    const heldDirection = heldDirections[0];
    if (heldDirection) {
        if (heldDirection === Directions.RIGHT) { newPosition.x += speed; }
        if (heldDirection === Directions.LEFT) { newPosition.x -= speed; }
        if (heldDirection === Directions.DOWN) { newPosition.y += speed; }
        if (heldDirection === Directions.UP) { newPosition.y -= speed; }
        facing = heldDirection;
    }
    setCharacterAttribute('facing', facing);
    insertFacingInKeys();
    setCharacterAttribute('walking', heldDirection ? 'true' : 'false');

    // Process the hitboxes and update the current position
    const hasMoved = checkPosition(newPosition, true);

    if (hasMoved) {
        // Check if there are automatic events at the new position
        checkAutomaticEvents();
        // Check if there are areas at the new position
        checkAreas();
        // Apply the modifications to the map
        updateMapPosition(newPosition);
    }
    // Display an indicator if an event can be triggered
    const event = getEvent();
    setCharacterAttribute('icon', event ? 'true' : 'false');
    // The character is positionned in the map (can slightly move even when blocked)
    setCharacterPosition(newPosition);
};

// Display the additional entities on the map
const processSprites = () => {
    const {currentMap} = getGameState();
    const {sprites} = MAPS[currentMap];
    sprites.forEach((sprite) => {
        setSpritePosition(sprite);
    });
};

const render = () => {
    // If the map is changing, trigger the loading
    if (getChangingMap() && !isLoadingTriggered) {
        isLoadingTriggered = true;
        triggerLoading();
    }

    // If there are ongoing events, process them
    if (processEvent()) {
        return;
    }

    // If a selector has changed, wait for the observer to find the new element
    if (isElementUndefined()) {
        return;
    }

    // If an action key is pressed, process the action
    // If the action doesn't trigger anything, the player will still be able to move
    if (currentAction && processAction()) {
        return;
    }

    processCharacter();
    processSprites();

    // Increment the time loop
    incrementTimeLoop();
};

document.addEventListener('keydown', (e) => {
    // If the game is not started yet, let the title screen container manage player actions
    if (!isGameStarted) {
        return;
    }
    // If an event is already ongoing, let the event container manage player actions
    if (getEventOngoing()) {
        return;
    }
    // If the menu is open, let the menu container manage player actions
    if (isMenuOpen) {
        return;
    }
    const dir = DirectionKeys[e.code];
    if (dir && heldDirections.indexOf(dir) === -1) {
        heldDirections.unshift(dir);
    }
    const action = ActionKeys[e.code];
    // Only one action at the time
    if (action && !currentAction) {
        if (action === ActionKeys.Enter || action === ActionKeys.Space) {
            currentAction = action;
        }
    }
});

document.addEventListener('keyup', (e) => {
    // If the game is not started yet, let the title screen container manage player actions
    if (!isGameStarted) {
        return;
    }
    // If an event is already ongoing, let the event container manage player actions
    if (getEventOngoing()) {
        return;
    }
    // If the menu is open, let the menu container manage player actions
    if (getMenuOpen()) {
        return;
    }
    const dir = DirectionKeys[e.code];
    const index = heldDirections.indexOf(dir);
    if (index > -1) {
        heldDirections.splice(index, 1);
    }
    if (e.code === 'KeyP') {
        characterIndex = (characterIndex + 1) % 5;
        changeCharacter(AVAILABLE_CHARACTERS[characterIndex]);
    }
});

// Game loop variables
const fps = 60;
const interval = 1000 / fps;
let lastTime = window.performance.now();
let currentTime = 0;
let delta = 0;

export const GameContainer: React.FC = () => {
    const [map, setMap] = useState(getGameState().currentMap);
    const [char, setChar] = useState(getGameState().currentCharacter);
    const [menuOpen, setMenuOpen] = useState(getMenuOpen());
    const {identifier, sprite: SelectedCharacter} = CHARACTERS[char];
    const {map: SelectedMap = undefined, sprites: spriteList = []} = MAPS[map] || {};

    const requestRef: any = useRef();

    // Update the component fields with the global values
    const update = useCallback(() => {
        const {currentMap, currentCharacter} = getGameState();
        if (currentMap !== map) {
            setMap(currentMap);
        }
        if (currentCharacter !== char) {
            setChar(currentCharacter);
        }
        setMenuOpen(getMenuOpen());
    }, [map, char]);

    // Game loop
    useEffect(() => {
        const step = (newtime) => {
            requestRef.current = window.requestAnimationFrame(step);
            // Limit the processing speed to avoid inconsistencies between devices
            currentTime = newtime;
            delta = (currentTime - lastTime);
            if (delta > interval) {
                lastTime = currentTime - (delta % interval);
                update();
                render();
            }
        };
        lastTime = window.performance.now();
        requestRef.current = window.requestAnimationFrame(step);
        return () => window.cancelAnimationFrame(requestRef.current);
    }, [update]);

    const renderSprites = useCallback(() => spriteList.map((sprite, index) => (
        <sprite.sprite key={index} identifier={sprite.identifier}/>
    )), [spriteList]);

    return (
        <Container className='frame-container'>
            <SoundContainer />
            {isGameStarted
                ? <Fragment>
                    <LoadingScreenContainer />
                    {SelectedMap
                    && <SelectedMap>
                        {SelectedCharacter && <SelectedCharacter identifier={identifier}/>}
                        {renderSprites()}
                    </SelectedMap>
                    }
                    <DialogContainer />
                    <div className='event-frame'></div>
                    <MenuScreenContainer menuOpen={menuOpen}/>
                </Fragment>
                : <TitleScreenContainer />}
        </Container>
    );
};