import {HeaderLink, SlideMenu, Spring} from 'components';
import React, {useEffect, useState} from 'react';
import AudioPlayer from 'react-modular-audio-player';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import {initApp, playMusic, playSound} from 'redux/actions';
import {RootState} from 'redux/reducers';
import {SongType} from 'redux/reducers/MusicReducer';
import styled from 'styled-components';

type ContainerProps = {
    hideHeader: boolean
}

const Container = styled.header<ContainerProps>`
    height: 54px;
    min-height: 54px;
    flex: 0 1 auto;
    padding: 10px 20px;
    display: flex;
    flex-flow: row;
    ${(props) => `background: ${props.hideHeader ? 'rgb(0, 0, 0)' : 'rgba(255, 255, 255)'};`}
    ${(props) => `background: ${props.hideHeader ? 'rgb(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0.6)'};`}
    font-size: 0.8rem;
    color: black;
    transition: 0.9s;

    .header-links {
        ${(props) => `display: ${props.hideHeader ? 'none' : 'flex'};`}
        flex: 1 1 auto;

        div {
            margin: 0 20px;
            animation: fade-in-translate2 .4s linear;
            animation-fill-mode: both;

            :nth-child(1) { animation-delay: .3s }
            :nth-child(2) { animation-delay: .4s }
            :nth-child(3) { animation-delay: .5s }
            :nth-child(4) { animation-delay: .6s }
            :nth-child(5) { animation-delay: .7s }
            :nth-child(6) { animation-delay: .8s }
        }
    }

    .bm-burger-button {
        display: none;
        position: relative;
        width: 36px;
        height: 30px;
        left: 15px;
        top: 10px;
    }

    .bm-burger-bars {
        background: #373a47;
    }

    .bm-burger-bars-hover {
        background: #a90000;
    }

    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    .bm-cross {
        background: #bdc3c7;
    }

    .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
    }

    .bm-menu {
        background: #373a47;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
    }

    .bm-item {
        display: flex;
        color: white;
        text-decoration: none;
        font-size: 42px;
    }

    .bm-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 900px) {
        .bm-burger-button {
            ${(props) => `display: ${props.hideHeader ? 'none' : 'flex'};`}
        }
        .header-links {
            display: none;
        }
    }

    @keyframes fade-in-translate2 {
        0% {
            opacity: 0;
            transform: translateY(-100px);
        }
        60% {
            opacity: 1;
            transform: translateY(20px);
        }
        87% {
            opacity: 1;
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    .audio-player {
        border-radius: 5px;
        padding: 3px;
        transition: 0.9s;
        ${(props) => `background: ${props.hideHeader ? 'rgba(255, 255, 255)' : 'rgb(0, 0, 0)'};`}
        ${(props) => `background: ${props.hideHeader ? 'rgba(255, 255, 255, 0.6)' : 'rgb(0, 0, 0, 0)'};`}
    }
`;

/*
const rearrangedPlayer = [
    {
        className: 'tier-top',
        innerComponents: [
            {
                type: 'name',
                style: {width: 'fit-content'}
            }
        ]
    },
    {
        className: 'tier-middle',
        style: {padding: '0 3px', justifyContent: 'space-between'},
        innerComponents: [
            {
                type: 'seek',
                style: {width: '100px'}
            },
            {
                type: 'time',
                style: {width: 'fit-content'}
            }
        ]
    },
    {
        className: 'tier-bottom',
        innerComponents: [
            {
                type: 'play',
                style: {width: 'fit-content'}
            },
            {
                type: 'rewind',
                style: {width: 'fit-content'}
            },
            {
                type: 'forward',
                style: {width: 'fit-content'}
            },
            {
                type: 'volume',
                style: {width: '80px', marginLeft: 'auto'}
            }
        ]
    }
];
*/

const mapStateToProps = (state: RootState) => ({
    isAppStarted: state.app.isStarted,
    isMusicPlaying: state.music.isPlaying,
    playlist: state.music.playlist,
    currentSong: state.music.singleSong,
    isSoundPlaying: state.sound.isPlaying,
    soundSrc: state.sound.src,
    replay: state.sound.increment
});

const connector = connect(mapStateToProps);

type HeaderConnectedProps = ConnectedProps<typeof connector>;

type HeaderProps = RouteComponentProps & HeaderConnectedProps & {

}

const startApp = () => {
    initApp();
    playMusic();
    playSound();
};

const Header: React.FC<HeaderProps> = (props) => {
    // const [currentPlaylist, setCurrentPlaylist] = useState<SongType[]>(props.playlist);
    const [musicRef] = useState<React.RefObject<AudioPlayer>>(React.createRef());
    const [audioRef] = useState<React.RefObject<HTMLAudioElement>>(React.createRef());
    const location = useLocation().pathname;
    const hideHeader = ['/home', '/test', '/siccinct'].includes(location);

    // Init the app and allow music
    useEffect(() => {
        if (props.isAppStarted) {
            return;
        }
        startApp();
        /* displayModal({
            title: 'Welcome to Pretty Patterns',
            text: `This website will play music and sounds. To enjoy the full experience, it
                is recommended that you keep the volume activated and that you wear earphones
                or headphones.`,
            type: ModalType.ALERT,
            onConfirm: startApp
        }); */
    }, [props.history, props.isAppStarted, props.location.pathname]);

    // Manage the playlist
    useEffect(() => {
        if (props.currentSong) {
            const playlist: SongType[] = [];
            playlist.push(props.currentSong);
            // setCurrentPlaylist(playlist);
        } else {
            // setCurrentPlaylist(props.playlist);
        }
        playMusic();
    }, [props.currentSong, props.playlist]);

    // Manage the music player
    useEffect(() => {
        if (props.isAppStarted && musicRef.current) {
            if (props.isMusicPlaying) {
                musicRef.current.handleRewind();
                musicRef.current.handlePlay();
            } else {
                musicRef.current.handlePause();
            }
        }
    }, [props.isMusicPlaying, musicRef, props.isAppStarted]);

    // Manage the sound source
    useEffect(() => {
        if (props.soundSrc || props.replay > 0) {
            playSound();
            playMusic();
        }
    }, [props.replay, props.soundSrc]);

    // Manage the sound player
    useEffect(() => {
        if (props.isAppStarted && audioRef.current) {
            if (props.isSoundPlaying) {
                audioRef.current.load();
                // audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [props.isSoundPlaying, audioRef, props.isAppStarted]);

    if (!props.isAppStarted || hideHeader) {
        return <React.Fragment />;
    }

    return (
        <Container hideHeader={hideHeader}>
            <audio ref={audioRef} controls={false}>
                {props.soundSrc && (
                    <source id='audio' src={props.soundSrc} type='audio/mp3' />
                )}
            </audio>


            <div className='header-menu'>
                <SlideMenu />
            </div>

            <div className='header-links'>
                <HeaderLink to={'/home'}>HOME</HeaderLink>
                <HeaderLink to={'/bio'}>BIOGRAPHY</HeaderLink>
                <HeaderLink to={'/portfolio'}>PORTFOLIO</HeaderLink>
                <HeaderLink to={'/social'}>SOCIALS</HeaderLink>
                <HeaderLink to={'/commissions'}>COMMISSIONS</HeaderLink>
                {/*
                        <HeaderLink to={'/von'}>VON</HeaderLink>
                        <HeaderLink to={'/gallery'}>GALLERY</HeaderLink>
                        */}
            </div>

            <Spring />
            {/* props.isMusicPlaying
                ? <AudioPlayer
                    ref={musicRef}
                    audioFiles={currentPlaylist}
                    rearrange={rearrangedPlayer}
                    playerWidth='160px'
                    hideLoop
                    loopPlaylist={currentPlaylist.length > 1}
                />
                : <AudioPlayerFiller />
            */}
        </Container>
    );
};

export const ConnectedHeader = connector(withRouter(Header));