
export type DialogSpriteDescriptor = {
    path: string
}

export const DIALOG_SPRITES: {[name: string]: DialogSpriteDescriptor} = {
    ANGRY: {path: '/assets/game/dialogSprites/lisa_angry.png'},
    CRYING: {path: '/assets/game/dialogSprites/lisa_crying.png'},
    DISTRUSTFUL: {path: '/assets/game/dialogSprites/lisa_distrustful.png'},
    HAPPY: {path: '/assets/game/dialogSprites/lisa_happy.png'},
    HORRIFIED: {path: '/assets/game/dialogSprites/lisa_horrified.png'},
    LAUGHING: {path: '/assets/game/dialogSprites/lisa_laughing.png'},
    NEUTRAL: {path: '/assets/game/dialogSprites/lisa_neutral.png'},
    SAD: {path: '/assets/game/dialogSprites/lisa_sad.png'},
    SMUG: {path: '/assets/game/dialogSprites/lisa_smug.png'},
    THINKING: {path: '/assets/game/dialogSprites/lisa_thinking.png'},
    UNINTERESTED: {path: '/assets/game/dialogSprites/lisa_uninterested.png'},
    BITTERSWEET: {path: '/assets/game/dialogSprites/lisa_bittersweet_smile.png'}
};

export const DIALOG_SPEED = {
    PAUSE: 500,
    SUPER_SLOW: 400,
    VERY_SLOW: 200,
    SLOW: 100,
    NORMAL: 50,
    FAST: 30,
    VERY_FAST: 15,
    SUPER_FAST: 5
};

/** A dialog is made of a list of lines. Each line has its own parameters.
 *
 *  N.B several lines can be displayed on the same level horizontaly. A line can be formed of a
 *  single word or even a single letter. The line separation is only made to allow styling and
 *  speed configuration.
 */
export type DialogLineDescriptor = {
    text: string,
    /** A higher value means a longer delay */
    speed: number,
    stick?: boolean,
    pause?: boolean,
    color?: string,
    style?: string,
    weight?: string
}

/** A discussion is made of a list of dialogs, each containing a list of lines. Each dialog is
 *  spoken by a single character which can be represented by a name and / or a sprite.
 */
export type DialogDescriptor = {
    name?: string,
    lines: DialogLineDescriptor[],
    leftSprite?: DialogSpriteDescriptor,
    rightSprite?: DialogSpriteDescriptor
}

export interface ChoiceDescriptor extends DialogDescriptor {
    choices: string[];
}