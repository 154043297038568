import React from 'react';
import styled from 'styled-components';

import {SpriteProps} from '../SpriteTypes';

const Container = styled.div`
    // The real dimensions of the character (EDIT_HERE)
    width: 32px;
    height: 32px;
    position: absolute;
    overflow: hidden;

    .shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("/assets/game/characters/Shadow.png") no-repeat no-repeat;
        background-size: 100%;
    }

    .spritesheet {
        position: absolute;
        background: url("/assets/game/characters/Bun.png") no-repeat no-repeat;
        background-size: 100%;
        // The real dimensions of the spritesheet (EDIT_HERE)
        width: 128px;
        height: 128px;
    }

    &[facing="right"] .spritesheet {
       background-position-y: -32px;
    }
    &[facing="up"] .spritesheet {
       background-position-y: -64px;
    }
    &[facing="left"] .spritesheet {
       background-position-y: -96px;
    }
    &[walking="false"] .spritesheet {
        transform: translate3d(-25%,0%,0);
    }
    &[walking="true"] .spritesheet {
       animation: walkAnimation 0.6s steps(4) infinite;
    }

    @keyframes walkAnimation {
        from {
            transform: translate3d(0%,0%,0);
        }
        to {
            transform: translate3d(-100%,0%,0);
        }
    }
`;

export const BunSprite: React.FC<SpriteProps> = (props) => {
    const characterProps = {facing: 'down', walking: 'true'};

    return (
        <Container className={`${props.identifier} sprite`} {...characterProps}>
            <div className='shadow pixel-art'></div>
            <div className='spritesheet pixel-art'></div>
        </Container>
    );
};