import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {SiccinctContainer} from 'siccinct-game/SiccinctContainer';

export const SiccinctPage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage('/assets/siccinct/background.jpg');
    }, []);

    return (
        <SiccinctContainer />
    );
};