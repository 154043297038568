import {BaseTile} from 'siccinct-game/sprites';
import {MAP_FRAME_HEIGHT, MAP_FRAME_WIDTH, MAP_RADIUS, TILE_HEIGHT, TILE_WIDTH} from './GameConstants';
import {Coordinates} from './GameTypes';
import {Hex} from './Hex';


const xDelta = (MAP_FRAME_WIDTH / 2) - (TILE_WIDTH / 2);
const yDelta = (MAP_FRAME_HEIGHT / 2) - (TILE_HEIGHT / 2);

/**
 * Returns the tile's coordinates in pixels. This position is based
 * on the Hex(0, 0) being in the center of the screen;
 * @param tile The tile to position
 * @returns The coordinates of the tile in pixels
 */
export const getTilePos : (
    tile: BaseTile
) => Coordinates = (
    tile
) => {
    const {q, r, s} = tile.hex;
    const realX = q * TILE_WIDTH + xDelta;
    const realY = (r - s) * (TILE_HEIGHT / 2) + yDelta;
    return {x: realX, y: realY};
};

export const getHexFromPos: (
    pos: Coordinates
) => Hex = (
    pos
) => {
    const {x, y} = pos;
    const realQ = Math.round(x / TILE_WIDTH);
    const realR = Math.round(y / TILE_HEIGHT - x / (2 * TILE_WIDTH));
    return new Hex(realQ, realR);
};

export const getTileZ : (
    tile: BaseTile
) => number = (
    tile
) => {
    const {r, s} = tile.hex;
    return r - s + MAP_RADIUS;
};

export const getTile : (
    list: BaseTile[], hex?: Hex
) => BaseTile | undefined = (
    list, hex
) => hex && list.find((tile) => tile.hex.equals(hex));