import React from 'react';
import styled from 'styled-components';

import {SpriteProps} from '../SpriteTypes';

const Container = styled.div`
    // The real dimensions of the character (EDIT_HERE)
    position: absolute;
    width: 32px;
    height: 32px;

    .icon {
        opacity: 0;
    }

    &[icon="true"] .icon {
        opacity: 1;
        position: absolute;
        top: -16px;
        left: 8px;
        width: 16px;
        height: 16px;
        background: url("/assets/game/characters/Icon.png") no-repeat no-repeat;
        animation: bounce 0.6s;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
        animation-iteration-count: infinite;
    }

    @keyframes bounce {
        from {
            transform: translate3d(0, -5px, 0);
        }

        to {
            transform: translate3d(0, 0px, 0);
        }
    }


    .shadow {
        position: absolute;
        bottom: -2px;
        width: 100%;
        height: 100%;
        background: url("/assets/game/characters/Shadow.png") no-repeat no-repeat;
        background-size: 100%;
    }

    .sprite {
        // The real dimensions of the character (EDIT_HERE)
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .spritesheet {
            position: absolute;
            background: url("/assets/game/characters/Lisa.png") no-repeat no-repeat;
            background-size: 100%;
            // The real dimensions of the spritesheet (EDIT_HERE)
            width: 128px;
            height: 128px;
        }
    }

    &[facing="right"] .sprite .spritesheet {
        background-position-y: -32px;
    }
    &[facing="up"] .sprite .spritesheet {
        background-position-y: -64px;
    }
    &[facing="left"] .sprite .spritesheet {
        background-position-y: -96px;
    }
    &[walking="false"] .sprite .spritesheet {
        transform: translate3d(-25%,0%,0);
    }
    &[walking="true"] .sprite .spritesheet {
        animation: walkAnimation 0.6s steps(4) infinite;
    }

    @keyframes walkAnimation {
        from {
            transform: translate3d(0%,0%,0);
        }
        to {
            transform: translate3d(-100%,0%,0);
        }
    }
`;

export const LisaSprite: React.FC<SpriteProps> = (props) => {
    const characterProps = {facing: 'down', walking: 'true', icon: ''};

    return (
        <Container className={`${props.identifier}`} {...characterProps}>
            <div className='shadow pixel-art'></div>
            <div className='sprite'>
                <div className='spritesheet pixel-art'></div>
            </div>
            <div className='icon pixel-art'></div>
        </Container>
    );
};