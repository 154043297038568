import React, {useCallback, useState} from 'react';
import {MENU_FRAME_HEIGHT, MENU_FRAME_WIDTH, TILE_WIDTH} from 'siccinct-game/utils/GameConstants';
import {Hex} from 'siccinct-game/utils/Hex';
import styled from 'styled-components';
import {BaseModule, BaseTile} from '../sprites';
import {Tile} from './Tile';

const MenuContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    .tile-display {
        position: absolute;
        left: ${MENU_FRAME_WIDTH / 2 - TILE_WIDTH}px;
        top: ${MENU_FRAME_HEIGHT / 2}px;
        scale: 2;
    }
`;

type MenuProps = {
    selectedTile?: BaseTile,
    build: (hex: Hex, module: BaseModule) => void,
    recycle: (hex?: Hex) => void
};

export const Menu: React.FC<MenuProps> = ({
    selectedTile, build, recycle
}) => {
    const [module, setModule] = useState(selectedTile && selectedTile.module);

    const buildAction = useCallback(() => {
        if (!selectedTile || !module) {
            return;
        }
        build(selectedTile.hex, module);
    }, [build, module, selectedTile]);

    // Build / Recycle / ???
    const renderActions = useCallback(() => (
        <div className='build-action' onClick={buildAction} />
    ),
    [buildAction]);

    return (
        <MenuContainer className='menu'>
            {selectedTile && `${selectedTile.type} - ${selectedTile.printKey()}`}
            <div className='tile-display'>
                {selectedTile && <Tile fixed tile={{
                    ...selectedTile,
                    module
                }} key={selectedTile.printKey()} />}
            </div>
            <div className='action-container'>
                {renderActions()}
            </div>
        </MenuContainer>
    );
};